import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get navigation
    navigationmenu: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.navigationmenu = []
    },
    ASSIGN_DATA_NAVIGATION(state, payload) {
        state.navigationmenu = payload;
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    // get Item Slider
    getNavigationMenu({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading = true;
            $axios.get(`navigationmenu`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_NAVIGATION', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
