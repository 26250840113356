import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    province: [],
    kota: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.province = [],
            state.kota = []
    },
    ASSIGN_DATA_PROVINCE(state, payload) {
        state.province = payload
    },
    ASSIGN_DATA_KOTA(state, payload) {
        state.kota = payload
    },

};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getProvince({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`provinsi`)
                .then((response) => {
                    commit('ASSIGN_DATA_PROVINCE', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getKota({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`kota`, {
                province_code: payload.province_code
            })
                .then((response) => {
                    commit('ASSIGN_DATA_KOTA', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
