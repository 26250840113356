<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="logo-container">
              <img
                data-src="/assets/img/logo-nasmoco-trans.png"
                class="img-fluid lazyload logo"
              />
            </div>
            <div class="row">
              <div class="logo-container">
                <a href="https://toss.nasmoco.co.id" target="_blank">
                <img
                  src="/assets/img/logo-toss.png"
                  class="img-fluid lazyload"
                />
                </a>
            </div>
            </div>
          </div> 
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-4 col-6">
                <div
                  class="footer-column"
                  v-for="(row, index) in navigationmenu"
                  :key="index"
                >
                  <div
                    v-if="
                      row.category == 'INFO PERUSAHAAN' ||
                      row.category == 'PENJUALAN'
                    "
                  >
                    <h4 class="title">{{ row.category }}</h4>
                    <ul
                      v-for="(row_c, index_c) in navigationmenu[index]
                        .detail_menu"
                      :key="index_c"
                    >
                      <li v-if="row_c.name == 'Simulasi Kredit'">
                        <router-link
                          :to="{
                            name: 'Credit_simulation',
                          }"
                          href=""
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#credit-modal"
                          >{{ row_c.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_c.name == 'Jaringan Dealer'">
                        <router-link
                          :to="{
                            name: 'Find_dealer',
                          }"
                          href=""
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#credit-modal"
                          >{{ row_c.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_c.name == 'Book Test Drive'">
                        <router-link
                          :to="{
                            name: 'Test_drive',
                          }"
                          href=""
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#credit-modal"
                          >{{ row_c.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_c.name == 'Konsultasi Pembelian'">
                        <router-link
                          :to="{
                            name: 'Contact_us',
                          }"
                          href=""
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#credit-modal"
                          >{{ row_c.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_c.name == 'Pricelist'">
                        <router-link
                          :to="{
                            name: 'Pricelist_page',
                          }"
                          href=""
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#credit-modal"
                          >{{ row_c.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_c.name == 'Brochure'">
                        <router-link
                          :to="{
                            name: 'Download_brochure',
                          }"
                          href=""
                          data-dismiss="modal"
                          data-toggle="modal"
                          data-target="#credit-modal"
                          >{{ row_c.name }}</router-link
                        >
                      </li>
                      <li v-else>
                        <a href="" @click.prevent="toSinglePage(row_c.link)">{{
                          row_c.name
                        }}</a>
                      </li>
                    </ul>
                    <ul v-if="
                      row.category == 'INFO PERUSAHAAN'
                    ">
                        <li>
                          <a href="/lead-sales" target="_blank">Lead Sales</a>
                        </li>
                    </ul>
                    
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-6">
                <div
                  class="footer-column"
                  v-for="(row, index) in navigationmenu"
                  :key="index"
                >
                  <div
                    v-if="
                      row.category == 'BERITA' || row.category == 'AFTERSALES'
                    "
                  >
                    <h4 class="title">{{ row.category }}</h4>
                    <ul
                      v-for="(row_content, index_c) in navigationmenu[index]
                        .detail_menu"
                      :key="index_c"
                    >
                      <li v-if="row_content.name == 'Book Service'">
                        <router-link
                          :to="{
                            name: 'Booking_service',
                          }"
                          href=""
                          >{{ row_content.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_content.name == 'Toyota Genuine Part'">
                        <router-link
                          :to="{
                            name: 'Sparepart',
                          }"
                          href=""
                          >{{ row_content.name }}</router-link
                        >
                      </li>
                      <li
                        v-else-if="
                          row_content.name == 'Program Aftersales' ||
                          row_content.name == 'Info Service'
                        "
                      >
                        <router-link
                          :to="{
                            name: 'After-sale',
                          }"
                          href=""
                          >{{ row_content.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_content.name == 'News & Promo'">
                        <router-link
                          :to="{
                            name: 'Promo',
                          }"
                          href=""
                          >{{ row_content.name }}</router-link
                        >
                      </li>
                      <li v-else-if="row_content.name == 'Info Garansi'">
                        <a
                          href=""
                          @click.prevent="toSinglePage('info-garansi')"
                          >{{ row_content.name }}</a
                        >
                      </li>
                      <li
                        v-else-if="
                          row_content.name == 'Nasmoco Emergency Assistance'
                        "
                      >
                        <a
                          href=""
                          @click.prevent="
                            toSinglePage('nasmoco-emergency-asistance')
                          "
                          >{{ row_content.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-12 col-6">
                    <div class="footer-column">
                      <h4 class="title">HUBUNGI KAMI</h4>

                      <p>Layanan Pelanggan</p>
                      <ul class="sosial-media">
                        <li>
                          <a
                            href="https://www.facebook.com/NasmocoWorld"
                            target="_blank"
                            class="icon"
                            ><i class="fab fa-facebook"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/NASMOCOworld"
                            target="_blank"
                            class="icon"
                            ><i class="fab fa-twitter"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/nasmoco_world/"
                            target="_blank"
                            class="icon"
                            ><i class="fab fa-instagram"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <div class="footer-column">
                      <h4 class="title">DOWNLOAD APPS</h4>

                      <ul>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=nasmoco.com.nasapp"
                            target="_blank"
                            >Nasmoco Apps</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Footer",
  created() {
    this.getNavigationMenu();
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState("footer", {
      navigationmenu: (state) => state.navigationmenu,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapActions("footer", ["getNavigationMenu"]),
    toSinglePage(seo) {
      this.$Progress.start();
      this.$router.push({
        name: "Single_page",
        params: {
          profil: seo,
        },
      });
      this.$Progress.finish();
    },
  },
};
</script>