<template>
  <div>
    <section
      class="intro-md"
      data-background="/assets/img/bg-8.png"
      style="background-image: url('assets/img/bg-8.png')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="block-text pt-lg-5">
              <h2 class="section-heading">{{ after_sale_by_id.title }}</h2>
              <p class="section-description-sm">
                {{ after_sale_by_id.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="serve-section bg-white section-lg pt-lg-0 pt-4 section-lg-top--5"
    >
      <div class="container">
        <div class="flex-3-column justify-content-start">
          <div
            class="flex-col"
            v-for="(row, index) in after_sale_detail"
            :key="index"
          >
            <div class="card card-style rounded trans-ease-in-out">
              <div class="card-body">
                <div
                  class="icon icon-lg yellow mb-lg-4 rounded trans-ease-in-out"
                >
                  <img
                    :data-src="row.image"
                    alt=""
                    class="img-fluid lazyloaded"
                    :src="row.image"
                  />
                </div>
                <h4 class="section-description">{{ row.title }}</h4>
                <p class="section-description-sm">
                  {{ row.teks }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AfterSaleDetail",
  data() {
    return {};
  },
  computed: {
    ...mapState("after_sale", {
      after_sale_by_id: (state) => state.after_sale_by_id,
      after_sale_detail: (state) => state.after_sale_detail,
    }),
  },
  methods: {
    ...mapActions("after_sale", ["getAfterSaleByID"]),
  },
  created() {
    this.getAfterSaleByID(this.$route.params.link);
    window.scrollTo(0, 0);
  },

  mounted() {
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
};
</script>