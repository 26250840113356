import $axios from "../../services/api.js";
const state = () => ({
    single_page_id: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.single_page_id = []
    },
    ASSIGN_DATA_SINGLE_PAGE_BY_ID(state, payload) {
        state.single_page_id = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getSinglePageByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`singlepage/${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_SINGLE_PAGE_BY_ID', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
