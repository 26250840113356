<template>
  <div>
    <section class="section-lg pb-0 dashboard-step">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 m-auto">
            <div class="card card-style">
              <div class="card-body" v-if="result_booking_order.length != 0">
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <p class="section-description-xs mb-0 opacity-7">
                    <span class="font-weight-600">Tanggal:</span>
                    {{ result_booking_order.tanggal | myDate }}
                  </p>
                </div>

                <div class="row">
                  <div class="col-lg-5">
                    <div class="wrap-content pb-0 px-3 border">
                      <div
                        class="d-flex justify-content-between align-items-center mb-2"
                      >
                        <p class="section-description-sm font-weight-bold">
                          PESANAN ANDA
                        </p>
                        <p
                          class="section-description-sm font-weight-bold text-accent"
                        >
                          {{ result_booking_order.order_id }}
                        </p>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 col-6 mb-2">
                          <p class="section-description-sm text-2">
                            Kategori Mobil
                          </p>
                          <p class="section-description-sm text-3">
                            {{ result_booking_order.car_category }}
                          </p>
                        </div>
                        <div class="col-lg-6 col-6 mb-2">
                          <p class="section-description-sm text-2">Model</p>
                          <p class="section-description-sm text-3">
                            {{ result_booking_order.car_model }}
                          </p>
                        </div>
                        <div class="col-lg-6 col-6 mb-2">
                          <p class="section-description-sm text-2">Transmisi</p>
                          <p class="section-description-sm text-3">
                            {{ result_booking_order.car_transmission }}
                          </p>
                        </div>
                        <div class="col-lg-6 col-6 mb-2">
                          <p class="section-description-sm text-2">Tipe</p>
                          <p class="section-description-sm text-3">
                            {{ result_booking_order.car_type }}
                          </p>
                        </div>
                        <div class="col-lg-6 col-6 mb-2">
                          <p class="section-description-sm text-2">
                            Pilihan Warna
                          </p>
                          <p class="section-description-sm text-3">
                            {{ result_booking_order.car_color }}
                          </p>
                        </div>
                        <div class="col-lg-6 col-6 mb-2">
                          <p class="section-description-sm text-2">Cabang</p>
                          <p class="section-description-sm text-3">
                            {{ result_booking_order.cabang }}
                          </p>
                        </div>
                      </div>

                      <hr class="mb-2" />

                      <div v-if="result_booking_order.payment_type == 'cash'">
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <p
                            class="section-description-sm font-weight-bold mb-0"
                          >
                            JENIS PEMBAYARAN ANDA
                          </p>
                          <span
                            class="badge badge-tipe-pembayaran section-description-sm px-5 px-lg-5 px-4"
                            >CASH</span
                          >
                        </div>
                        <hr class="mt-2" />
                        <div class="row">
                          <div class="col-lg-6 mb-2">
                            <p class="section-description-sm text-2">
                              Total Biaya Mobil Anda
                            </p>
                            <h4 class="section-description font-weight-800">
                              {{ result_booking_order.biaya_mobil | currency }}
                            </h4>
                          </div>
                          <div
                            class="col-lg-6 mb-2"
                            v-if="result_booking_order.kode_voucher != ''"
                          >
                            <p class="section-description-sm text-2">
                              Kode Promo:
                              {{ result_booking_order.kode_voucher }}
                            </p>
                            <h4 class="section-description font-weight-800">
                              {{ result_booking_order.potongan | currency }}
                            </h4>
                          </div>
                          <div
                            class="col-lg-7 mb-2"
                            v-if="result_booking_order.kode_voucher != ''"
                          >
                            <p class="section-description-sm text-2">
                              Grand Total Biaya Mobil Anda
                            </p>
                            <h4 class="section-description font-weight-800">
                              {{ result_booking_order.grand_total | currency }}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div v-if="result_booking_order.payment_type == 'kredit'">
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <p
                            class="section-description-sm font-weight-bold mb-0"
                          >
                            JENIS PEMBAYARAN ANDA
                          </p>
                          <span
                            class="badge badge-tipe-pembayaran section-description-sm px-lg-5 px-4"
                            >KREDIT</span
                          >
                        </div>

                        <div class="row bg-light py-3 mt-2">
                          <div class="col-lg-6">
                            <p class="section-description-sm text-2">
                              Total Biaya Mobil Anda
                            </p>
                            <h4
                              class="section-description font-weight-800 mb-0"
                            >
                              {{ result_booking_order.biaya_mobil | currency }}
                            </h4>
                          </div>
                          <div class="col-lg-12 my-3">
                            <div class="price-display-2">
                              <div class="box-price">
                                <h5
                                  class="section-description-sm font-weight-bold mb-0"
                                >
                                  {{
                                    result_booking_order.uang_muka | currency
                                  }}
                                </h5>
                                <p
                                  class="section-description-xs mb-0 opacity-7"
                                >
                                  Uang Muka
                                </p>
                              </div>
                              <div class="box-price">
                                <h5
                                  class="section-description-sm font-weight-bold mb-0"
                                >
                                  {{ result_booking_order.tenor }}
                                </h5>
                                <p
                                  class="section-description-xs mb-0 opacity-7"
                                >
                                  Tenor / Jangka Waktu
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-2">
                            <p class="section-description-sm text-2">
                              Angsuran per bulan harga normal
                            </p>
                            <h4
                              v-if="result_booking_order.kode_voucher == ''"
                              class="section-description font-weight-800"
                            >
                              <p>
                                {{ result_booking_order.angsuran | currency }}
                              </p>
                            </h4>
                            <h4
                              v-if="result_booking_order.kode_voucher != ''"
                              class="section-description font-weight-800 text-accent"
                            >
                              <del>{{
                                result_booking_order.angsuran | currency
                              }}</del>
                            </h4>
                          </div>
                          <div
                            class="col-lg-6 mb-2"
                            v-if="result_booking_order.kode_voucher != ''"
                          >
                            <p class="section-description-sm text-2">
                              Angsuran per bulan promo
                              <span class="font-weight-bold text-dark">{{
                                result_booking_order.kode_voucher
                              }}</span>
                            </p>
                            <h4 class="section-description font-weight-800">
                              {{
                                result_booking_order.angsuran_promo | currency
                              }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-7 mt-lg-0 mt-4">
                    <div
                      class="d-flex flex-column justify-content-between h-100"
                    >
                      <div>
                        <h4 class="section-description font-weight-bold mb-3">
                          Konfirmasi Booking
                        </h4>

                        <ul class="list-info-style flex flex-2">
                          <li>
                            <label>Name:</label>
                            <span>{{ result_booking_order.full_name }}</span>
                          </li>
                          <li>
                            <label>E-mail:</label>
                            <span>{{ result_booking_order.member_email }}</span>
                          </li>
                          <li>
                            <label>Phone:</label>
                            <span>{{ result_booking_order.phone }}</span>
                          </li>
                          <li>
                            <label>Address:</label>
                            <span>{{ result_booking_order.address }}</span>
                          </li>
                        </ul>

                        <p class="section-description-sm">
                          Konfirmasi pembayaran Nasmoco. Pembayaran anda akan
                          diverifikasi paling lambat 1 x 24 jam oleh sales
                          representatif kami.
                        </p>

                        <div class="wrap-konf-booking-fee mt-3">
                          <div class="card-booking-fee">
                            <p
                              class="section-description-sm font-weight-800 mb-0"
                            >
                              Booking Fee
                            </p>
                            <h2
                              class="section-description-lg font-weight-800 text-accent mb-1"
                            >
                              {{ result_booking_order.bookingfee | currency }}
                            </h2>
                            <p class="section-description-sm mb-0">
                              Booking fee merupakan tanda jadi pembelian mobil
                              Anda.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="d-md-flex justify-content-between">
                        <div class="my-lg-0 my-3">
                          <p
                            class="section-description-xs font-weight-600 text-accent mb-0"
                          >
                            *Syarat dan Ketentuan
                          </p>
                          <p
                            class="section-description-xs font-weight-600 text-accent mb-0"
                          >
                            *Harga berlaku periode bulan berjalan
                          </p>
                        </div>
                        <div class="text-lg-right text-center">
                          <a
                            @click="gotoConfirm()"
                            class="btn btn-lg btn-accent py-2 px-3 font-weight-600"
                            >BOOKING SEKARANG
                            <i class="fa fa-chevron-right ml-2"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderInfo",
  metaInfo() {
    return {
      title: "Order Info - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {};
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    var vm = this;
    // attr data-background
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
  computed: {
    ...mapState("booking_order", {
      result_booking_order: (state) => state.result_booking_order,
      bookingfee: (state) => state.bookingfee,
    }),
  },
  methods: {
    gotoConfirm() {
      this.$router.push({
        name: "Order_success",
      });
    },
  },
};
</script>