<template>
  <div>
    <section
      class="intro-md"
      data-background="assets/img/bg-7.png"
      style="background-image: url('assets/img/bg-7.png')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 m-auto">
            <div class="block-text pt-lg-5">
              <p class="section-subheading">TRADE IN</p>
              <h2 class="section-heading">
                Tukar Tambah Mobil (Trade In) Toyota
              </h2>
              <p class="section-description-sm mb-0">
                Tukar Tambah Mobil Toyota menjadi lebih mudah dengan program
                Trade In Nasmoco. AutoFamily bisa mendapatkan mobil Toyota
                pilihan sesuai keinginan dengan harga lebih murah. Hanya perlu
                menukarkan mobil yang sekarang dimiliki dengan tambahan dana,
                maka AutoFamily sudah bisa mendapatkan mobil Toyota terbaru.
                Nikmati program Trade In bersama Nasmoco sekarang juga.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="formulir-section section-lg pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <form method="post" action="javascript:void(0)" class="form-style">
              <div class="card">
                <!-- Data Pribadi  -->
                <div class="card-header"><span>Data Pribadi</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Nama</label>
                            <input
                              :disabled="isAuth"
                              v-model.trim="$v.form_trade_in.name.$model"
                              :class="{
                                'is-invalid': $v.form_trade_in.name.$error,
                                'is-valid':
                                  !$v.form_trade_in.name.$error &&
                                  form_trade_in.name != '',
                              }"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan nama lengkap Anda"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_trade_in.name.required"
                            >
                              *Masukkan Nama Lengkap!.
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Email</label>
                            <input
                              :disabled="isAuth"
                              v-model.trim="$v.form_trade_in.email.$model"
                              :class="{
                                'is-invalid': $v.form_trade_in.email.$error,
                                'is-valid':
                                  !$v.form_trade_in.email.$error &&
                                  form_trade_in.email != '',
                              }"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan alamat email Anda"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_trade_in.email.required"
                            >
                              *Masukkan email!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_trade_in.email.email"
                            >
                              *Email tidak valid!.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">No. Telepon</label>
                            <input
                              v-model.trim="$v.form_trade_in.phone.$model"
                              :class="{
                                'is-invalid': $v.form_trade_in.phone.$error,
                                'is-valid':
                                  !$v.form_trade_in.phone.$error &&
                                  form_trade_in.phone != '',
                              }"
                              :disabled="isAuth"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan No. Telepon Aktif Anda"
                              @keypress="isNumber($event)"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_trade_in.phone.required"
                            >
                              *Masukkan No Telp!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_trade_in.phone.minLength"
                            >
                              *No. Telp minimal 10 digit!.
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-5">
                              <div class="form-group">
                                <label class="control-label"
                                  >Tanggal Lahir</label
                                >
                                <date-picker
                                  v-model.trim="$v.form_trade_in.birth.$model"
                                  :class="{
                                    'is-invalid': $v.form_trade_in.birth.$error,
                                    'is-valid':
                                      !$v.form_trade_in.birth.$error &&
                                      form_trade_in.birth != '',
                                  }"
                                  placeholder="Tanggal lahir"
                                  :disabled="isAuth"
                                  class="form-control"
                                  :config="options"
                                ></date-picker>
                                <div
                                  class="invalid-feedback"
                                  v-if="!$v.form_trade_in.phone.required"
                                >
                                  *Masukkan tanggal lahir!.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-7">
                              <div class="form-group">
                                <label class="control-label"
                                  >Jenis Kelamin</label
                                >
                                <div class="d-flex">
                                  <b-form-radio-group
                                    :disabled="isAuth"
                                    button-variant="danger"
                                    v-model="form_trade_in.gender"
                                    :options="gender_option"
                                    name="radio-inline"
                                  ></b-form-radio-group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Provinsi</label>
                                <v-select
                                  :disabled="isAuth"
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="province"
                                  label="provinsi"
                                  placeholder="Pilih Provinsi"
                                  :value="form_trade_in.province_name"
                                  @input="setValIdProvince"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Provinsi tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.provinsi
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Kabupaten/Kota</label
                                >
                                <v-select
                                  :disabled="isAuth"
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="kota"
                                  label="nama"
                                  placeholder="Pilih Kota"
                                  :value="form_trade_in.city_name"
                                  @input="setValIdKota"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Kota tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.nama
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Alamat</label>
                            <textarea
                              v-model.trim="$v.form_trade_in.address.$model"
                              :class="{
                                'is-invalid': $v.form_trade_in.address.$error,
                                'is-valid':
                                  !$v.form_trade_in.address.$error &&
                                  form_trade_in.address != '',
                              }"
                              :disabled="isAuth"
                              name=""
                              rows="2"
                              class="form-control"
                              placeholder="Address"
                            ></textarea>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_trade_in.address.required"
                            >
                              *Masukkan Alamat!.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-header"><span>Detail Mobil Anda</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Merek mobil</label>
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="brand"
                              label="brand_name"
                              placeholder="Pilih Brand"
                              @input="setValIdBrand"
                              :value="form_trade_in.brand_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Brand tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.brand_name
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Model Mobil</label>
                            <v-select
                              :disabled="form_trade_in.brand == ''"
                              class="vselect-custome"
                              style="width: 100%"
                              :options="brand_model"
                              label="brand_model_name"
                              placeholder="Pilih Brand Model"
                              @input="setValIdBrandModel"
                              :value="form_trade_in.brand_model_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Brand Model tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.brand_model_name
                              }}</template>
                            </v-select>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Tahun Pembuatan</label>
                            <v-select
                              :disabled="form_trade_in.brand_model == ''"
                              class="vselect-custome"
                              style="width: 100%"
                              :options="brand_model_year"
                              label="old_car_year"
                              placeholder="Pilih Tahun"
                              @input="setValBrandYear"
                              :value="form_trade_in.brand_year"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Tahun tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.old_car_year
                              }}</template>
                            </v-select>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Tipe Mobil</label>
                            <v-select
                              :disabled="form_trade_in.brand_model == ''"
                              class="vselect-custome"
                              style="width: 100%"
                              :options="brand_type"
                              label="old_car_type_name"
                              placeholder="Pilih Tipe Mobil"
                              @input="setValIdBrandType"
                              :value="form_trade_in.brand_type_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Tipe Mobil tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.old_car_type_name
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mt-2">
                        <div class="d-flex">
                          <div
                            class="
                              custom-control custom-radio custom-control-inline
                            "
                          >
                            <input
                              class="custom-control-input"
                              type="radio"
                              name="trade_in"
                              id="trade_in1"
                              value="tt1"
                            />
                            <label class="custom-control-label" for="trade_in1"
                              >Tukar tambah mobil Saya</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Mobil Impian Anda  -->
                <div class="card-header"><span>Mobil Impian Anda</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Kategori Mobil</label>
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="car_category"
                              label="category_name"
                              placeholder="Pilih Kategori Mobil"
                              @input="setValIdkategoriMobil"
                              :value="form_trade_in.car_category_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Kategori Mobil tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.category_name
                              }}</template>
                            </v-select>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Model Mobil</label>
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="car_model"
                              label="car_mode_name"
                              placeholder="Pilih Model Mobil"
                              @input="setValIdCarModel"
                              :value="form_trade_in.car_model_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Model Mobil tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.car_mode_name
                              }}</template>
                            </v-select>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Transmisi</label>
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="Car_Transmision"
                                  label="label"
                                  placeholder="Pilih Transmisi Mobil"
                                  @input="setValIdTransmisi"
                                  :value="form_trade_in.car_transmission"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Transmisi tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.label
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Tipe Mobil</label>
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_type"
                                  label="car_type_name"
                                  placeholder="Pilih Tipe Mobil"
                                  @input="setValIdCarType"
                                  :value="form_trade_in.car_type_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Tipe Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.car_type_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Kabupaten/Kota</label
                                >
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="kota_cabang"
                                  label="city_name"
                                  placeholder="Pilih Kota Cabang"
                                  @input="setValIdKotaCabang"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Kota Tidak Ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.city_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Cabang</label>
                                <v-select
                                  :disabled="form_trade_in.kota_cabang == ''"
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="cabang"
                                  label="cabang_name"
                                  placeholder="Pilih Cabang"
                                  @input="setValIdCabang"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Cabang Tidak Ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.cabang_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="text-center">
                        <button
                          :disabled="$v.form_trade_in.$invalid"
                          v-if="!isSave"
                          type="submit"
                          class="
                            btn btn-padd btn-width
                            text-center
                            btn-yellow
                            mt-4
                          "
                          @click.prevent="submit"
                        >
                          SUBMIT <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                        <button
                          v-if="isSave"
                          type="submit"
                          class="
                            btn btn-padd btn-width
                            text-center
                            btn-yellow
                            mt-4
                          "
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "TradeIn",
  metaInfo() {
    return {
      title: "Trade In - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      gender_option: [
        { text: "Laki-laki", value: "Laki-laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],
      jenis_service: [
        { text: "General Service", value: "general-service" },
        { text: "Body & Paint", value: "body-paint" },
      ],
      Car_Transmision: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
      tahun: [
        {
          value: "",
        },
      ],
    };
  },
  created() {
    this.getProvince();
    this.getCarCategory();
    this.getBrand();
    this.getKotaCabang();
    if (this.isAuth) {
      this.form_trade_in.name = this.isSharedPreference.full_name;
      this.form_trade_in.email = this.isSharedPreference.member_email;
      this.form_trade_in.phone = this.isSharedPreference.phone;
      this.form_trade_in.birth = this.isSharedPreference.birth;
      this.form_trade_in.gender = this.isSharedPreference.gender;
      this.form_trade_in.province = this.isSharedPreference.province_id;
      this.form_trade_in.province_name = this.isSharedPreference.province_name;
      this.form_trade_in.city = this.isSharedPreference.city_id;
      this.form_trade_in.city_name = this.isSharedPreference.city_name;
      this.form_trade_in.address = this.isSharedPreference.address;
      this.form_trade_in.token = this.isSharedPreference.token;
    }
    window.scrollTo(0, 0);
  },
  watch: {
    form_trade_in: {
      handler(newValue) {
        if (newValue.province != "") {
          this.getKota({
            province_code: newValue.province,
          });
        }
        if (newValue.brand != "") {
          this.getBrandModel({
            brand_id: newValue.brand,
          });
        }
        if (newValue.brand != "" && newValue.brand_model) {
          this.getBrandYear({
            brand_id: newValue.brand,
            brand_model_id: newValue.brand_model,
          });
        }
        if (newValue.brand_model != "" && newValue.brand_year) {
          this.getBrandType({
            brand_id: newValue.brand,
            brand_model_id: newValue.brand_model,
            brand_model_year: newValue.brand_year,
          });
        }
        if (newValue.car_category != "") {
          this.getCarModel({
            category_id: newValue.car_category,
          });
        }
        if (newValue.car_transmission != "" && newValue.car_model) {
          this.getCarType({
            car_model_id: newValue.car_model,
            car_transmission: newValue.car_transmission,
          });
        }
        if (newValue.kota_cabang != "") {
          this.getCabang({
            city_code: newValue.kota_cabang,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    // data-backgronud
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
  validations: {
    form_trade_in: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      birth: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      address: {
        required,
      },
      gender: {
        required,
      },
      province: {
        required,
      },
      city: {
        required,
      },
      brand: {
        required,
      },
      brand_model: {
        required,
      },
      brand_year: {
        required,
      },
      brand_type: {
        required,
      },
      car_category: {
        required,
      },
      car_model: {
        required,
      },
      car_transmission: {
        required,
      },
      car_type: {
        required,
      },
      kota_cabang: {
        required,
      },
      cabang_id: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["isSave"]),
    ...mapGetters(["isAuth", "isToken", "isSharedPreference"]),
    ...mapState(["errors"]),
    ...mapState("regencies", {
      province: (state) => state.province,
      kota: (state) => state.kota,
    }),
    ...mapState("member", {
      tabContent: (state) => state.tabContent,
    }),
    ...mapState("cabang", {
      kota_cabang: (state) => state.kota_cabang,
      cabang: (state) => state.cabang,
    }),
    ...mapState("detail_car", {
      car_category: (state) => state.car_category,
      car_model: (state) => state.car_model,
      car_type: (state) => state.car_type,
    }),
    ...mapState("trade_in", {
      brand: (state) => state.brand,
      brand_model: (state) => state.brand_model,
      brand_model_year: (state) => state.brand_year,
      brand_type: (state) => state.brand_type,
      form_trade_in: (state) => state.form_trade_in,
    }),

    tabContent: {
      get() {
        return this.$store.state.member.tabContent;
      },
      set(val) {
        this.$store.commit("member/SET_TAB_CONTENT", val);
      },
    },
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("detail_car", [
      "getCarCategory",
      "getCarModel",
      "getCarType",
    ]),
    ...mapActions("regencies", ["getProvince", "getKota"]),
    ...mapActions("cabang", ["getKotaCabang", "getCabang"]),
    ...mapActions("trade_in", [
      "getBrand",
      "getBrandModel",
      "getBrandYear",
      "getBrandType",
      "insertDataTradeIn",
      "Clear_State",
    ]),

    setDobValue(value) {
      this.form_trade_in.birth = value;
    },
    // is number
    // keypres untuk input float data type
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // regencies
    setValIdProvince(val) {
      this.form_trade_in.province = val.code;
      this.form_trade_in.province_name = val.provinsi;
      this.form_trade_in.city = "";
      this.form_trade_in.city_name = "";
    },
    setValIdKota(val) {
      this.form_trade_in.city = val.kode;
      this.form_trade_in.city_name = val.nama;
    },

    // car model
    setValIdkategoriMobil(val) {
      this.form_trade_in.car_category = val.category_id;
      this.form_trade_in.car_category_name = val.category_name;

      this.form_trade_in.car_model = "";
      this.form_trade_in.car_model_name = "";
      this.form_trade_in.car_transmission = "";
      this.form_trade_in.car_type = "";
      this.form_trade_in.car_type_name = "";
    },
    setValIdTransmisi(val) {
      this.form_trade_in.car_transmission = val.value;
    },
    setValIdCarModel(val) {
      this.form_trade_in.car_model = val.car_model_id;
      this.form_trade_in.car_model_name = val.car_mode_name;
      this.form_trade_in.car_type = "";
      this.form_trade_in.car_type_name = "";
    },
    setValIdCarType(val) {
      this.form_trade_in.car_type = val.car_type_id;
      this.form_trade_in.car_type_name = val.car_type_name;
    },

    //brand
    setValIdBrand(val) {
      this.form_trade_in.brand = val.brand_id;
      this.form_trade_in.brand_name = val.brand_name;
      this.form_trade_in.brand_model = "";
      this.form_trade_in.brand_model_name = "";
      this.form_trade_in.brand_year = "";
      this.form_trade_in.brand_type = "";
      this.form_trade_in.brand_type_name = "";
    },
    setValIdBrandModel(val) {
      this.form_trade_in.brand_model = val.brand_model_id;
      this.form_trade_in.brand_model_name = val.brand_model_name;
      this.form_trade_in.brand_year = "";
      this.form_trade_in.brand_type = "";
      this.form_trade_in.brand_type_name = "";
    },
    setValBrandYear(val) {
      this.form_trade_in.brand_year = val.old_car_year;
      this.form_trade_in.brand_type = "";
      this.form_trade_in.brand_type_name = "";
    },
    setValIdBrandType(val) {
      this.form_trade_in.brand_type = val.old_car_type_id;
      this.form_trade_in.brand_type_name = val.old_car_type_name;
    },

    // set val cabang
    setValIdKotaCabang(val) {
      this.form_trade_in.kota_cabang = val.city_code;
      this.form_trade_in.kota_cabang_name = val.city_name;
      this.form_trade_in.cabang_id = "";
      this.form_trade_in.cabang_id_name = "";
    },
    setValIdCabang(val) {
      this.form_trade_in.cabang_id = val.cabang_id;
      this.form_trade_in.cabang_id_name = val.cabang_name;
    },

    //insert trade_ins
    submit() {
      this.$Progress.start();
      this.insertDataTradeIn()
        .then((res) => {
          this.CLEAR_ERRORS();
          swal.fire({
            title: "Trade In berhasil ditambahkan",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.tabContent = 6;
          this.$router.push({
            name: "Dashboard_member",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          swal.fire({
            text: this.errors,
            icon: "error",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.fail();
        });
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>
<style>
.datepicker table tr td span {
  width: 40%;
}
</style>