import Vue from 'vue'
import Vuex from 'vuex'

//auth 
import auth from "../store/auth/auth.js"
//home
import home from "../store/home/home.js";
// header
import header from "../store/header/header.js";
// footer
import footer from "../store/footer/footer.js";
// nasmoco journey
import nasmoco_journey from "../store/nasmoco_journey/nasmoco_journey.js";
//promo
import promo from "../store/promo/promo.js";
// find dealer
import find_dealer from "../store/find_dealer/find_dealer.js"
// after sale 
import after_sale from "../store/after_sale/after_sale.js"
// car model 
import detail_car from "../store/car_model/detail_car.js"
//sparepart
import sparepart from "../store/sparepart/sparepart.js"
// regencies
import regencies from "../store/regencies/regencies.js"
// cabang 
import cabang from "../store/cabang/cabang.js"
// booking service
import booking_service from "../store/booking_service/booking_service.js"
// booking order
import booking_order from "../store/booking_order/booking_order.js"
// trade in
import trade_in from "../store/trade_in/trade_in.js"
// test drive
import test_drive from "../store/test_drive/test_drive.js"
// member
import member from "../store/member/member.js"
//single page
import single_page from "../store/single_page/single_page"
// pricelist
import pricelist from "../store/pricelist/pricelist.js"
// download_brochure
import download_brochure from "../store/brochure/download_brochure.js"
// contact us
import contact_us from "../store/contact_us/contact_us.js"
// compare car 
import compare_car from "../store/compare_car/compare_car.js"
// credit simualation
import credit_simulation from "../store/credit_simulation/credit_simulation.js"
// nasmoco club
import nasmoco_club from "../store/nasmoco_club/nasmoco_club.js"
// load sales
import lead_sales from "./lead_sales/lead_sales.js"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    //auth
    auth,
    //home
    home,
    // header 
    header,
    //footer
    footer,
    //nasmoco_journey
    nasmoco_journey,
    // promo
    promo,
    // after sale 
    after_sale,
    // car
    detail_car,
    //sparepart
    sparepart,
    // regencies
    regencies,
    // cabang
    cabang,
    //booking service
    booking_service,
    //booking order
    booking_order,
    // trade in
    trade_in,
    // test drive
    test_drive,
    // find dealer
    find_dealer,
    // member
    member,
    //single page
    single_page,
    //pricelist
    pricelist,
    //download_brochure
    download_brochure,
    // contact us
    contact_us,
    // compare_car
    compare_car,
    // cedit simulation
    credit_simulation,
    // nasmoco club
    nasmoco_club,
    // lead sales
    lead_sales
  },
  state: {
    token: localStorage.getItem("token") || null,
    sharedPreference: JSON.parse(localStorage.getItem("sharedPreference")) || null,
    errors: "",
    isSave: false,
  },
  getters: {
    isAuth: (state) => {
      return state.token != "null" && state.token != null;
    },
    isSharedPreference: (state) => {
      return state.sharedPreference;
    },
    isToken: (state) => {
      return state.token;
    },
    isErrors: (state) => {
      return state.errors != "null" && state.token != null;
    }
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_SHARED_PREFERENCE(state, payload) {
      state.sharedPreference = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    SET_SAVE(state, payload) {
      state.isSave = payload;
    },
    CLEAR_ERRORS(state) {
      state.errors = [];
    },
    CLEAR_IS_SAVE(state) {
      state.isSave = false
    }
  },
  actions: {
    //Destroy Is Save 
    ClearIsSave({ commit }) {
      return new Promise(() => {
        commit("CLEAR_IS_SAVE");
      });
    },
  }
});

export default store;
