import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get slider
    sparepart: [],
    total: 0,
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.sparepart = []
    },
    ASSIGN_DATA_SPARE_PART(state, payload) {
        state.sparepart = payload
    },
    ASSIGN_DATA_SPARE_PART_TOTAL(state, payload) {
        state.total = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getSparepart({
        commit,
        state
    }, payload) {
        let search = typeof payload.search != 'undefined' ? payload.search : ''
        return new Promise((resolve, reject) => {
            $axios.get(`sparepart?&filter=${payload.filter}&q=${search}`)
                .then((response) => {
                    commit('ASSIGN_DATA_SPARE_PART', response.data.data)
                    commit('ASSIGN_DATA_SPARE_PART_TOTAL', response.data.data_count)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
