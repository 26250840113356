import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    form_test_drive: {
        name: "",
        email: "",
        phone: "",
        address: "",
        gender: "",
        birth: new Date(),
        province: "",
        province_name: "",
        city: "",
        city_name: "",
        car_category: "",
        car_category_name: "",
        car_model: "",
        car_model_name: "",
        car_transmission: "",
        car_transmission_name: "",
        car_type: "",
        car_type_name: "",
        kota_cabang: "",
        kota_cabang_name: "",
        cabang_id: "",
        cabang_id_name: "",
        appointment_date: new Date(),
        appointment_time: "",
        buycar: "",
        note: "",
        token: "",
        channel: "test-drive"
    },
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.form_test_drive = {
                name: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                birth: new Date(),
                province: "",
                province_name: "",
                city: "",
                city_name: "",
                car_category: "",
                car_category_name: "",
                car_model: "",
                car_model_name: "",
                car_transmission: "",
                car_transmission_name: "",
                car_type: "",
                car_type_name: "",
                kota_cabang: "",
                kota_cabang_name: "",
                cabang_id: "",
                cabang_id_name: "",
                appointment_date: new Date(),
                appointment_time: "",
                buycar: "",
                note: "",
                token: "",
                channel: "test-drive"
            }
    },
    CLEAR_FORM(state) {
        state.form_test_drive = {
            name: "",
            email: "",
            phone: "",
            address: "",
            gender: "",
            birth: new Date(),
            province: "",
            province_name: "",
            city: "",
            city_name: "",
            car_category: "",
            car_category_name: "",
            car_model: "",
            car_model_name: "",
            car_transmission: "",
            car_transmission_name: "",
            car_type: "",
            car_type_name: "",
            kota_cabang: "",
            kota_cabang_name: "",
            cabang_id: "",
            cabang_id_name: "",
            appointment_date: new Date(),
            appointment_time: "",
            buycar: "",
            note: "",
            token: "",
            channel: "test-drive"
        }
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    insertDataTestDrive({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`testdrive`, state.form_test_drive)
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (state.form_test_drive.token != "") {
                        commit('CLEAR_FORM')
                    }
                    else if (state.form_test_drive.token == "") {
                        commit('CLEAR_FORM')
                        //remove localstorage
                        localStorage.removeItem('token');
                        localStorage.removeItem('sharedPreference');
                        //set localstorage
                        localStorage.setItem('token', response.data.data.login.token)
                        localStorage.setItem('sharedPreference', JSON.stringify(response.data.data.login));
                        commit('SET_TOKEN', response.data.data.login.token, {
                            root: true
                        })
                        commit('SET_SHARED_PREFERENCE', response.data.data.login, {
                            root: true
                        })
                    }
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 400) {
                        commit('SET_ERRORS', error.response.data.message.alert, {
                            root: true
                        })
                    }
                    if (error.response.data.code == 422) {
                        commit('SET_ERRORS', error.response.data.message, {
                            root: true
                        })
                    }
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
