import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get slider
    category: "",
    pricelist: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.category = "",
            state.pricelist = []
    },
    ASSIGN_DATA_PRICELIST(state, payload) {
        state.pricelist = payload;
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    // get Item Slider
    getPriceList({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading = true;
            $axios.get(`pricelist`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_PRICELIST', response.data.data.category)
                    state.category = response.data.data.category[0].category_name;
                    state.loading = false;
                    resolve(response.data)
                })
        })
    },

};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
