import axios from 'axios';

const $axios = axios.create({
    baseURL: 'https://cmspwa.nasmoco.co.id/pwa/api/v1/',
    // baseURL: 'https://trialit2.nasmoco.co.id/pwa/api/v1/',
    // baseURL: 'http://localhost/api-nasmoco-master/',
    // baseURL: 'https://api.ufotostudio.com/v1/',
    // baseURL: 'http://localhost:902/cmspwa.nasmoco.co.id/pwa/api/v1/',
    // baseURL: 'http://localhost:902/cmspwa_new/pwa/api/v1/',

    headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'omWOZwbYfEpHWa5ApvMxQ37WAdxXmAXoMRl4eV2fSmH5ARuEMSd09bigrJgULGrz'
    }
});

export default $axios;