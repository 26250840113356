<template>
  <div>
    <section class="section-lg order-success pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 px-lg-0 m-auto">
            <div class="text-center">
              <img
                data-src="assets/img/img-credit-card.png"
                class="img-fluid mb-4 mt-3 lazyloaded"
                src="assets/img/img-credit-card.png"
              />
              <h4 class="section-description font-weight-bold">
                Konfirmasi Booking Berhasil, Silahkan transfer booking fee
                dengan nominal di bawah ini
              </h4>
              <h3
                class="section-description-lg font-weight-800"
                v-if="bookingfee != 0"
              >
                {{ bookingfee | currency }}
              </h3>
              <h3 class="section-description-md font-weight-bold">
                Bank Mandiri Cab. Pemuda Semarang a/n PT. New Ratna Motor
              </h3>
              <p class="section-description no-rekening">135.000.8077.990</p>

              <p class="section-description-xs">
                Pembayaran anda akan diverifikasi paling lambat 1 x 24 jam. Jika
                lebih dari 24 jam anda belum mendapatkan email verifikasi
                pembayaran segera hubungi kami.
                <b>Konfirmasi pembayaran</b> melalui dashboard member Anda atau
                melalui whatssapp di bawah ini
              </p>

              <div class="d-md-flex align-items-center justify-content-center">
                <a
                  @click="toConfirmBooking()"
                  class="text-accent font-weight-bold mr-lg-4 mr-md-3"
                  ><u>Konfirmasi Pembayaran</u></a
                >
                <a
                  href="https://wa.me/628981260060"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp px-3 font-weight-bold mt-4 mt-md-0"
                  ><span class="ion-social-whatsapp-outline font-16"></span>
                  Kontak Melalui Whatsapp</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderSuccess",
  metaInfo() {
    return {
      title:
        "Order Success - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {};
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {},
  computed: {
    ...mapState("booking_order", {
      bookingfee: (state) => state.bookingfee,
    }),
    ...mapState("member", {
      tabContent: (state) => state.tabContent,
      form_confirm_payment: (state) => state.form_confirm_payment,
    }),
    tabContent: {
      get() {
        return this.$store.state.member.tabContent;
      },
      set(val) {
        this.$store.commit("member/SET_TAB_CONTENT", val);
      },
    },
  },
  methods: {
    ...mapActions("member", ["Set_Booking_Fee"]),
    toConfirmBooking() {
      this.$Progress.start();
      this.Set_Booking_Fee(this.bookingfee);
      this.tabContent = 7;
      this.$router.push({
        name: "Dashboard_member",
      });
      this.$Progress.finish();
    },
  },
};
</script>