<template>
  <div>
    <section class="formulir-section section-lg">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="box-section-title text-center my-4 my-lg-0">
              <h2 class="section-heading-sm">
                <span>Form</span> Booking Service
              </h2>
              <p class="section-description-sm">
                Kenyamanan Mobil Anda, Merupakan Prestasi Kami. Silahkan Isi
                Form Booking Service dibawah ini, Kami akan segera meresponse
                sesuai permintaan anda.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <form class="form-style">
              <div class="card">
                <div class="card-header"><span>Data Pribadi</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Nama</label>
                            <input
                              :disabled="isAuth"
                              v-model.trim="$v.form_booking_service.name.$model"
                              :class="{
                                'is-invalid':
                                  $v.form_booking_service.name.$error,
                                'is-valid':
                                  !$v.form_booking_service.name.$error &&
                                  form_booking_service.name != '',
                              }"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan nama lengkap Anda"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.name.required"
                            >
                              *Masukkan Nama Lengkap!.
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="control-label">Email</label>
                            <input
                              :disabled="isAuth"
                              v-model.trim="
                                $v.form_booking_service.email.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_service.email.$error,
                                'is-valid':
                                  !$v.form_booking_service.email.$error &&
                                  form_booking_service.email != '',
                              }"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan alamat email Anda"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.email.required"
                            >
                              *Masukkan email!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.email.email"
                            >
                              *Email tidak valid!.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">No. Telepon</label>
                            <input
                              v-model.trim="
                                $v.form_booking_service.phone.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_service.phone.$error,
                                'is-valid':
                                  !$v.form_booking_service.phone.$error &&
                                  form_booking_service.phone != '',
                              }"
                              :disabled="isAuth"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan No. Telepon Aktif Anda"
                              @keypress="isNumber($event)"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.phone.required"
                            >
                              *Masukkan No Telp!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.phone.minLength"
                            >
                              *No. Telp minimal 10 digit!.
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-5">
                              <div class="form-group">
                                <label class="control-label"
                                  >Tanggal Lahir</label
                                >
                                <date-picker
                                  v-model.trim="
                                    $v.form_booking_service.birth.$model
                                  "
                                  :class="{
                                    'is-invalid':
                                      $v.form_booking_service.birth.$error,
                                    'is-valid':
                                      !$v.form_booking_service.birth.$error &&
                                      form_booking_service.birth != '',
                                  }"
                                  placeholder="Tanggal lahir"
                                  :disabled="isAuth"
                                  class="form-control"
                                  :config="options"
                                ></date-picker>
                                <div
                                  class="invalid-feedback"
                                  v-if="!$v.form_booking_service.phone.required"
                                >
                                  *Masukkan tanggal lahir!.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-7">
                              <div class="form-group">
                                <label class="control-label"
                                  >Jenis Kelamin</label
                                >
                                <div class="d-flex">
                                  <b-form-radio-group
                                    :disabled="isAuth"
                                    button-variant="danger"
                                    v-model="form_booking_service.gender"
                                    :options="gender_option"
                                    name="radio-inline"
                                  ></b-form-radio-group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Provinsi</label>
                                <v-select
                                  :disabled="isAuth"
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="province"
                                  label="provinsi"
                                  placeholder="Pilih Provinsi"
                                  :value="form_booking_service.province_name"
                                  @input="setValIdProvince"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Provinsi tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.provinsi
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Kabupaten/Kota</label
                                >
                                <v-select
                                  :disabled="isAuth"
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="kota"
                                  label="nama"
                                  placeholder="Pilih Kota"
                                  :value="form_booking_service.city_name"
                                  @input="setValIdKota"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Kota tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.nama
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Alamat</label>
                            <textarea
                              v-model.trim="
                                $v.form_booking_service.address.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_service.address.$error,
                                'is-valid':
                                  !$v.form_booking_service.address.$error &&
                                  form_booking_service.address != '',
                              }"
                              :disabled="isAuth"
                              name=""
                              rows="2"
                              class="form-control"
                              placeholder="Address"
                            ></textarea>
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.address.required"
                            >
                              *Masukkan Alamat!.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-header"><span>Data Kendaraan</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Kategori Mobil</label
                                >
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_category"
                                  label="category_name"
                                  placeholder="Pilih Kategori Mobil"
                                  @input="setValIdkategoriMobil"
                                  :value="
                                    form_booking_service.car_category_name
                                  "
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Kategori Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.category_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Transmisi</label>
                                <v-select
                                  :disabled="
                                    form_booking_service.car_category_name == ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="Car_Transmision"
                                  label="label"
                                  placeholder="Pilih Transmisi Mobil"
                                  @input="setValIdTransmisi"
                                  :value="form_booking_service.car_transmission"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Transmisi tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.label
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Model Mobil</label>
                                <v-select
                                  :disabled="
                                    form_booking_service.car_transmission == ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_model"
                                  label="car_mode_name"
                                  placeholder="Pilih Model Mobil"
                                  @input="setValIdCarModel"
                                  :value="form_booking_service.car_model_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Model Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.car_mode_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Tipe Mobil</label>
                                <v-select
                                  :disabled="
                                    form_booking_service.car_model_name == ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="car_type"
                                  label="car_type_name"
                                  placeholder="Pilih Tipe Mobil"
                                  @input="setValIdCarType"
                                  :value="form_booking_service.car_type_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Tipe Mobil tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.car_type_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label class="control-label">No. Polisi</label>
                                <input
                                  v-model="form_booking_service.police_number"
                                  type="text"
                                  name=""
                                  class="form-control"
                                  placeholder="Isikan dengan no. polisi"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label class="control-label"
                                  >Tahun Pembuatan</label
                                >
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="tahun"
                                  label="value"
                                  placeholder="Pilih Tahun"
                                  @input="setValBuildYear"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Tahun tidak ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.value
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Kilometer</label>
                            <input
                              v-model.trim="
                                $v.form_booking_service.car_km.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_service.car_km.$error,
                                'is-valid':
                                  !$v.form_booking_service.car_km.$error &&
                                  form_booking_service.car_km != '',
                              }"
                              @keypress="isNumber($event)"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Isikan dengan kilometer saat ini"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_service.car_km.required"
                            >
                              *Masukkan Kilomoter!.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-header"><span>Informasi Service</span></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-10 m-auto">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Jenis Service</label>
                            <div class="d-flex justify-content-between">
                              <b-form-radio-group
                                button-variant="danger"
                                v-model="form_booking_service.service_type"
                                :options="jenis_service"
                                name="radio-inline"
                              ></b-form-radio-group>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              form_booking_service.service_type ===
                              'general-service'
                            "
                          >
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Service Berkala Ke 1 s/d 20</label
                                >
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="periode_service_option"
                                  label="value"
                                  placeholder="Pilih Service Berkala ke "
                                  @input="setValIDServiceBerkala"
                                  :value="form_booking_service.service_ke"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Jangka Service Berkala tidak ada</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.value
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label class="control-label"
                                  >Tanggal Datang</label
                                >
                                <date-picker
                                  :class="{
                                    'form-control': 'form-control',
                                  }"
                                  v-model="
                                    form_booking_service.appointment_date
                                  "
                                  placeholder="Tanggal datang"
                                  :config="options"
                                  :disabledDates="disabledDates"
                                ></date-picker>
                                <small class="text-danger">
                                  *Min. 3 hari dari hari ini</small
                                >
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label class="control-label">Jam Datang</label>
                                <div
                                  class="input-group timepicker"
                                  id="jam_datang"
                                  data-target-input="nearest"
                                >
                                  <b-form-timepicker
                                    v-model="
                                      form_booking_service.appointment_time
                                    "
                                    locale="id"
                                  ></b-form-timepicker>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label"
                                  >Kabupaten/Kota</label
                                >
                                <v-select
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="kota_cabang"
                                  label="city_name"
                                  placeholder="Pilih Kota Cabang"
                                  @input="setValIdKotaCabang"
                                  :value="form_booking_service.kota_cabang_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Kota Tidak Ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.city_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label class="control-label">Cabang</label>
                                <v-select
                                  :disabled="
                                    form_booking_service.kota_cabang == ''
                                  "
                                  class="vselect-custome"
                                  style="width: 100%"
                                  :options="cabang"
                                  label="cabang_name"
                                  placeholder="Pilih Cabang"
                                  @input="setValIdCabang"
                                  :value="form_booking_service.cabang_id_name"
                                  :clearable="false"
                                >
                                  <template slot="no-options"
                                    >Cabang Tidak Ditemukan</template
                                  >
                                  <template slot="option" slot-scope="option">{{
                                    option.cabang_name
                                  }}</template>
                                </v-select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label class="control-label"
                              >Masukkan Kode Promo</label
                            >
                            <input
                              v-model="form_booking_service.promotion_code"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Kode Promo"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <textarea
                              v-model="form_booking_service.note"
                              name=""
                              rows="12"
                              class="form-control"
                              placeholder="Additional information (Optional)"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                              <div id='kebijakan-1'>
                                <input type="checkbox" id="kebijakan1" value="1" v-model="checked">
                                <label for="infokebijakan1"><p class="section-description-xs">
                                  <a href="https://nasmoco.co.id/info/kebijakan-privasi" target="_blank">Saya telah membaca dan memahami syarat dan ketentuan terkait data pribadi yang diakses dalam www.nasmoco.co.id 
                                  ("Kebijakan Privasi"), dan oleh karenanya saya dengan ini menyatakan dengan tegas dan tanpa paksaan bahwa saya memberikan 
                                  persetujuan serta terikat dengan seluruh syarat dan ketentuan yang diatur di dalam Kebijakan Privasi tersebut 
                                  kepada Nasmoco Group
                                </a>
                                </p></label>
                              </div> 
                            </div>
                          </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                              <div id='kebijakan-2'>
                                <input type="checkbox" id="kebijakan2" value="1" v-model="checked">
                                <label for="infokebijakan2"><p class="section-description-xs">
                                  Seluruh dokumen, data, dan/atau informasi pribadi yang saya sampaikan pada formulir ini adalah benar, sesuai dengan aslinya dan 
                                  dapat dipertanggungjawabkan, saya memberikan wewenang kepada Nasmoco Group untuk melakukan pemrosesan atas dokumen, data, 
                                  dan/atau informasi pribadi tersebut sesuai dengan ketentuan yang diatur dalam Kebijakan Privasi.
                                </p></label>
                              </div>                              
                            </div>
                          </div>
                      </div>     
                      
                      <div class="text-center">
                        <button
                          :disabled="$v.form_booking_service.$invalid"
                          v-if="!isSave"
                          type="submit"
                          class="
                            btn btn-padd btn-width
                            text-center
                            btn-yellow
                            mt-4
                          "
                          @click.prevent="submit"
                        >
                          SUBMIT <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                        <button
                          v-if="isSave"
                          type="submit"
                          class="
                            btn btn-padd btn-width
                            text-center
                            btn-yellow
                            mt-4
                          "
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "BookingService",
  metaInfo() {
    return {
      title:
        "Booking Service - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      disabledDates: {
        to: new Date(Date.now() + 8640000),
      },
      gender_option: [
        { text: "Laki-laki", value: "Laki-laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],
      periode_service_option: [
        { value: "1" },
        { value: "2" },
        { value: "3" },
        { value: "4" },
        { value: "5" },
        { value: "6" },
        { value: "7" },
        { value: "8" },
        { value: "9" },
        { value: "10" },
        { value: "11" },
        { value: "12" },
        { value: "13" },
        { value: "14" },
        { value: "15" },
        { value: "16" },
        { value: "17" },
        { value: "18" },
        { value: "19" },
        { value: "20" },
      ],
      jenis_service: [
        { text: "General Service", value: "general-service" },
        { text: "Body & Paint", value: "body-paint" },
      ],
      Car_Transmision: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
      tahun: [
        {
          value: "",
        },
      ],
    };
  },
  created() {
    this.getProvince();
    this.getCarCategory();
    this.getYear();
    this.getKotaCabang();
    if (this.isAuth) {
      this.form_booking_service.name = this.isSharedPreference.full_name;
      this.form_booking_service.email = this.isSharedPreference.member_email;
      this.form_booking_service.phone = this.isSharedPreference.phone;
      this.form_booking_service.birth = this.isSharedPreference.birth;
      this.form_booking_service.gender = this.isSharedPreference.gender;
      this.form_booking_service.province = this.isSharedPreference.province_id;
      this.form_booking_service.province_name =
        this.isSharedPreference.province_name;
      this.form_booking_service.city = this.isSharedPreference.city_id;
      this.form_booking_service.city_name = this.isSharedPreference.city_name;
      this.form_booking_service.address = this.isSharedPreference.address;
      this.form_booking_service.token = this.isSharedPreference.token;
    }
    window.scrollTo(0, 0);
  },
  watch: {
    form_booking_service: {
      handler(newValue) {
        if (newValue.province != "") {
          this.getKota({
            province_code: newValue.province,
          });
        }
        if (newValue.car_category != "") {
          this.getCarModel({
            category_id: newValue.car_category,
          });
        }
        if (newValue.car_transmission != "" && newValue.car_model) {
          this.getCarType({
            car_model_id: newValue.car_model,
            car_transmission: newValue.car_transmission,
          });
        }
        if (newValue.kota_cabang != "") {
          this.getCabang({
            city_code: newValue.kota_cabang,
          });
        }
      },
      deep: true,
    },
  },
  validations: {
    form_booking_service: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },

      birth: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      address: {
        required,
      },
      gender: {
        required,
      },
      car_category: {
        required,
      },
      car_model: { required },
      car_transmission: { required },
      car_type: { required },
      police_number: { required },
      build_year: { required },
      kota_cabang: { required },
      cabang_id: { required },
      car_km: { required },
      service_type: { required },
      appointment_date: { required },
      appointment_time: { required },
      kebijakan1 :  { required },
      kebijakan2 :  { required },
    },
  },
  mounted() {},
  computed: {
    ...mapState(["isSave"]),
    ...mapGetters(["isAuth", "isToken", "isSharedPreference"]),
    ...mapState(["errors"]),
    ...mapState("regencies", {
      province: (state) => state.province,
      kota: (state) => state.kota,
    }),
    ...mapState("member", {
      tabContent: (state) => state.tabContent,
    }),
    ...mapState("cabang", {
      kota_cabang: (state) => state.kota_cabang,
      cabang: (state) => state.cabang,
    }),
    ...mapState("detail_car", {
      car_category: (state) => state.car_category,
      car_model: (state) => state.car_model,
      car_type: (state) => state.car_type,
    }),
    ...mapState("booking_service", {
      form_booking_service: (state) => state.form_booking_service,
    }),

    tabContent: {
      get() {
        return this.$store.state.member.tabContent;
      },
      set(val) {
        this.$store.commit("member/SET_TAB_CONTENT", val);
      },
    },
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("detail_car", [
      "getCarCategory",
      "getCarModel",
      "getCarType",
    ]),
    ...mapActions("regencies", ["getProvince", "getKota"]),
    ...mapActions("cabang", ["getKotaCabang", "getCabang"]),
    ...mapActions("booking_service", [
      "insertDataBookingService",
      "Clear_State",
    ]),
    getYear() {
      var max = new Date().getFullYear();
      var min = 1900;
      for (var i = max; i >= min; i--) {
        this.tahun.push({
          value: i,
        });
      }
      this.tahun.splice(0, 1);
    },
    // is number
    // keypres untuk input float data type
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // regencies
    setValIdProvince(val) {
      this.form_booking_service.province = val.code;
      this.form_booking_service.province_name = val.provinsi;
      // clear kota
      this.form_booking_service.city = "";
      this.form_booking_service.city_name = "";
    },
    setValIdKota(val) {
      this.form_booking_service.city = val.kode;
      this.form_booking_service.city_name = val.nama;
    },
    setValIdkategoriMobil(val) {
      this.form_booking_service.car_category = val.category_id;
      this.form_booking_service.car_category_name = val.category_name;
      //  clear data
      this.form_booking_service.car_model = "";
      this.form_booking_service.car_model_name = "";
      this.form_booking_service.car_transmission = "";
      this.form_booking_service.car_type = "";
      this.form_booking_service.car_type_name = "";
    },
    setValIdTransmisi(val) {
      this.form_booking_service.car_transmission = val.value;
      // clear car type
      this.form_booking_service.car_type = "";
      this.form_booking_service.car_type_name = "";
    },
    setValIdCarModel(val) {
      this.form_booking_service.car_model = val.car_model_id;
      this.form_booking_service.car_model_name = val.car_mode_name;
      // clear type
      this.form_booking_service.car_type = "";
      this.form_booking_service.car_type_name = "";
    },
    setValIdCarType(val) {
      this.form_booking_service.car_type = val.car_type_id;
      this.form_booking_service.car_type_name = val.car_type_name;
    },
    setValBuildYear(val) {
      this.form_booking_service.build_year = val.value;
    },
    setValIdKotaCabang(val) {
      this.form_booking_service.kota_cabang = val.city_code;
      this.form_booking_service.kota_cabang_name = val.city_name;
      // clear cabang
      this.form_booking_service.cabang_id = "";
      this.form_booking_service.cabang_id_name = "";
    },
    setValIDServiceBerkala(val) {
      this.form_booking_service.service_ke = val.value;
    },
    setValIdCabang(val) {
      this.form_booking_service.cabang_id = val.cabang_id;
      this.form_booking_service.cabang_id_name = val.cabang_name;
    },
    //insert booking service
    submit() {
      this.$Progress.start();
      this.insertDataBookingService()
        .then((res) => {
          this.CLEAR_ERRORS();
          swal.fire({
            title: "Booking Service berhasil ditambahkan",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.tabContent = 4;
          this.$router.push({
            name: "Home",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          swal.fire({
            text: this.errors,
            icon: "error",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.fail();
        });
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>

<style>
.datepicker table tr td span {
  width: 40%;
}
</style>