<template>
  <div>
    <section
      class="intro-md"
      data-background="assets/img/bg-1.png"
      style="background-image: url('assets/img/bg-1.png')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="block-text pt-lg-5">
              <p class="section-subheading">CUSTOMER CARE</p>
              <h2 class="section-heading">Kami Siap Membantu Anda!</h2>
              <p class="section-description-sm">
                Silahkan Isi Form dibawah ini, Kami Siap Membantu Anda Untuk
                Mendapatkan Mobil Impian Anda
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="contact-us section-lg pt-lg-0 pt-4 section-lg-top--5 bg-image center"
      data-background="assets/img/bg-4.png"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-10 m-auto pr-lg-3 keep-touch">
            <div class="">
              <img
                data-src="assets/img/img-keep-in-touch.png"
                class="img-fluid lazyload mb-4 shadow-lg rounded-15 bg-accent"
              />
              <h3 class="section-heading-xs font-weight-bold">Keep in Touch</h3>
              <p class="section-description-xs">
                Customer Care Nasmoco Group Siap Membantu Anda.
              </p>
            </div>

            <div class="">
              <div
                class="card card-style card-call shadow-none rounded-10 mb-3"
              >
                <i class="fa fa-building text-white"></i>
                <p class="section-description-xs mb-0">
                  Jl. Inspeksi Gajahmada, Setos Building Lt 6 Semarang, Jawa
                  Tengah 50133
                </p>
              </div>

              <div class="card card-style card-call shadow-none rounded-10">
                <i class="fa fa-phone text-white"></i>
                <p class="section-description-xs font-weight-bold mb-1 mt-2">
                  <a href="https://wa.me/628981260060" target="_blank"
                    >0898 - 1260060</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-8 mt-lg-0 mt-4">
            <div class="card card-style rounded-15">
              <div class="card-body">
                <form method="post" class="form-style">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">Nama</label>
                        <input
                          :disabled="isAuth"
                          v-model.trim="$v.form_contact_us.name.$model"
                          :class="{
                            'is-invalid': $v.form_contact_us.name.$error,
                            'is-valid':
                              !$v.form_contact_us.name.$error &&
                              form_contact_us.name != '',
                          }"
                          type="text"
                          name=""
                          class="form-control"
                          placeholder="Isikan dengan nama lengkap Anda"
                        />
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.name.required"
                        >
                          *Masukkan Nama Lengkap!.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">Email</label>
                        <input
                          :disabled="isAuth"
                          v-model.trim="$v.form_contact_us.email.$model"
                          :class="{
                            'is-invalid': $v.form_contact_us.email.$error,
                            'is-valid':
                              !$v.form_contact_us.email.$error &&
                              form_contact_us.email != '',
                          }"
                          type="text"
                          name=""
                          class="form-control"
                          placeholder="Isikan dengan email Aktif Anda"
                        />
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.email.required"
                        >
                          *Masukkan email!.
                        </div>
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.email.email"
                        >
                          *Email tidak valid!.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">No. Telepon</label>
                        <input
                          :disabled="isAuth"
                          v-model.trim="$v.form_contact_us.phone.$model"
                          :class="{
                            'is-invalid': $v.form_contact_us.phone.$error,
                            'is-valid':
                              !$v.form_contact_us.phone.$error &&
                              form_contact_us.phone != '',
                          }"
                          type="text"
                          name=""
                          class="form-control"
                          placeholder="Isikan dengan No. Telepon Aktif Anda"
                          @keypress="isNumber($event)"
                        />
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.phone.required"
                        >
                          *Masukkan No Telp!.
                        </div>
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.phone.minLength"
                        >
                          *No. Telp minimal 10 digit!.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Alamat Anda</label>
                        <textarea
                          :disabled="isAuth"
                          v-model.trim="$v.form_contact_us.address.$model"
                          :class="{
                            'is-invalid': $v.form_contact_us.address.$error,
                            'is-valid':
                              !$v.form_contact_us.address.$error &&
                              form_contact_us.address != '',
                          }"
                          name=""
                          rows="5"
                          class="form-control"
                          placeholder="Isikan dengan Alamat domisili Anda"
                        ></textarea>
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.address.required"
                        >
                          *Masukkan Alamat!.
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Pertanyaan Anda</label>
                        <textarea
                          v-model.trim="$v.form_contact_us.text.$model"
                          :class="{
                            'is-invalid': $v.form_contact_us.text.$error,
                            'is-valid':
                              !$v.form_contact_us.text.$error &&
                              form_contact_us.text != '',
                          }"
                          name=""
                          rows="5"
                          class="form-control"
                          placeholder="Isikan dengan pertanyaan Anda"
                        ></textarea>
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_contact_us.text.required"
                        >
                          *Masukkan pesan anda!.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 order-lg-first align-self-end">
                      <p class="section-description-xxs mb-0">Catatan:</p>
                      <p class="section-description-xxs">
                        Saya mengizinkan Toyota dan mitranya untuk menghubungi
                        Saya dalam membantu proses pembelian mobil Toyota.
                        Dengan memberikan email dan no handphone, saya telah
                        menyetujui untuk menerima semua pemberitahuan melalui
                        Toyota.
                      </p>
                    </div>
                  </div>
                  <div class="text-lg-center text-center">
                    <button
                      :disabled="$v.form_contact_us.$invalid"
                      v-if="!isSave"
                      type="submit"
                      class="btn btn-padd btn-width text-center btn-yellow mt-4"
                      @click.prevent="submit"
                    >
                      KIRIM <i class="fa fa-arrow-right ml-2"></i>
                    </button>
                    <button
                      v-if="isSave"
                      :disabled="isSave"
                      type="submit"
                      class="btn btn-padd btn-width text-center btn-yellow mt-4"
                    >
                      <span class="spinner-border spinner-border-sm"></span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "ContactUs",
  metaInfo() {
    return {
      title: "Contact Us - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {};
  },
  created() {
    if (this.isAuth) {
      this.form_contact_us.name = this.isSharedPreference.full_name;
      this.form_contact_us.email = this.isSharedPreference.member_email;
      this.form_contact_us.phone = this.isSharedPreference.phone;
      this.form_contact_us.address = this.isSharedPreference.address;
    }
    window.scrollTo(0, 0);
  },
  validations: {
    form_contact_us: {
      name: { required },
      email: { required, email },
      phone: { required, minLength },
      address: { required },
      text: { required },
    },
  },
  watch: {
    form_contact_us: {
      handler(newValue) {},
      deep: true,
    },
  },
  mounted() {
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
  computed: {
    ...mapState(["isSave"]),
    ...mapGetters(["isAuth", "isToken", "isSharedPreference"]),
    ...mapState(["errors"]),
    ...mapState("contact_us", {
      form_contact_us: (state) => state.form_contact_us,
    }),
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("contact_us", ["insertContactUs", "Clear_State"]),
    // keypres untuk input float data type
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    //insert booking service
    submit() {
      this.$Progress.start();
      this.insertContactUs()
        .then((res) => {
          this.CLEAR_ERRORS();
          swal.fire({
            title: "Terimakasih telah menghubungi kami",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });

          this.$router.push({
            name: "Home",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          swal.fire({
            text: this.errors,
            icon: "error",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.fail();
        });
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>
