// import { ipAddress } from "vuelidate/lib/validators.js";
import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    form_lead_sales: {
        name: "",
        phone: "",
        agree1: "",
        agree2: "",
        ipAds:""
    },
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.form_lead_sales = {
                name: "",
                phone: "",
                agree1: "",
                agree2: "",
                ipAds:""
            }
    },
    CLEAR_FORM(state) {
        state.form_lead_sales = {
            name: "",
            phone: "",
            agree1: "",
            agree2: "",
            ipAds:""
        }
    },
};
const actions = {
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    insertLeadSales({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`leadsales`, state.form_lead_sales)
            .then((response) => {
                commit('SET_SAVE', false, {
                    root: true
                })
                commit('CLEAR_FORM')
                resolve(response.data)
            }).catch((error) => {
                commit('SET_SAVE', false, {
                    root: true
                })
                if (error.response.data.code == 422) {
                    commit('SET_ERRORS', "Periksa Kembali Semua Form", {
                        root: true
                    })
                }
                reject(error)
            })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
