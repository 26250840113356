<template>
  <div>
    <section class="section-lg dashboard-member dashboard-step bg-white mh-93">
      <div class="container">
        <div class="wrap-content pb-2">
          <h3 class="section-heading-xs">Detail Booking Service:</h3>

          <div
            class="d-flex justify-content-end align-items-center text-lg-right text-left border-none"
          >
            <div>
              <p class="section-description-sm">
                <span class="font-weight-600">{{
                  detail_booking_service_by_id.booking_id
                }}</span>
              </p>
              <p class="section-description-sm mb-0">
                {{ detail_booking_service_by_id.booking_date | myDate }}
              </p>
            </div>
          </div>
        </div>

        <div class="wrap-content pb-2">
          <h3 class="section-heading-xs">Customer</h3>

          <div class="row">
            <div class="col-lg-6">
              <ul class="list-info-style flex">
                <li>
                  <label>Name:</label>
                  <span>{{ isSharedPreference.full_name }}</span>
                </li>
                <li>
                  <label>E-mail:</label>
                  <span>{{ isSharedPreference.member_email }}</span>
                </li>
                <li>
                  <label>Phone:</label>
                  <span>{{ isSharedPreference.phone }}</span>
                </li>
                <li>
                  <label>Address:</label>
                  <span>{{ isSharedPreference.address }}</span>
                </li>
              </ul>
            </div>

            <div class="col-lg-6">
              <ul class="list-info-style flex">
                <li>
                  <label>Gender:</label>
                  <span>{{ isSharedPreference.gender }}</span>
                </li>
                <li>
                  <label>Birth:</label>
                  <span>{{ isSharedPreference.birth | myDate }}</span>
                </li>
                <li>
                  <label>Province:</label>
                  <span>{{ isSharedPreference.province_name }}</span>
                </li>
                <li>
                  <label>City:</label>
                  <span>{{ isSharedPreference.city_name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="wrap-content pb-2">
          <h3 class="section-heading-xs">Car Detail</h3>

          <div class="row">
            <div class="col-lg-6">
              <ul class="list-info-style flex">
                <li>
                  <label>Car Type:</label>
                  <span>{{ detail_booking_service_by_id.car_type }}</span>
                </li>
                <li>
                  <label>Car KM:</label>
                  <span>{{ detail_booking_service_by_id.km_mobil }}</span>
                </li>
                <li>
                  <label>Manufactured:</label>
                  <span>{{ detail_booking_service_by_id.tahun_mobil }}</span>
                </li>
                <li>
                  <label>Police Number:</label>
                  <span>{{ detail_booking_service_by_id.no_plat }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="wrap-content pb-2">
          <h3 class="section-heading-xs">Service</h3>

          <div class="row">
            <div class="col-lg-6">
              <ul class="list-info-style flex">
                <li>
                  <label>Service Date:</label>
                  <span>{{
                    detail_booking_service_by_id.tgl_service | myDate
                  }}</span>
                </li>
                <li>
                  <label>Service Type:</label>
                  <span>{{ detail_booking_service_by_id.jenis_service }}</span>
                </li>
                <li>
                  <label>Prefered Branch:</label>
                  <span>{{ detail_booking_service_by_id.branch_name }}</span>
                </li>
                <li>
                  <label>Status:</label>
                  <span
                    ><span
                      class="badge badge-pill badge-success w-auto font-12 px-3"
                      >{{ detail_booking_service_by_id.status }}</span
                    ></span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "DetailBookingService",
  metaInfo() {
    return {
      title:
        "Detail Booking Service - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState("member", {
      detail_booking_service_by_id: (state) =>
        state.detail_booking_service_by_id,
      loading: (state) => state.loading,
    }),
    ...mapGetters(["isSharedPreference"]),
  },
  methods: {
    ...mapActions("member", ["getDetailBookingServiceById"]),
  },
  created() {
    this.getDetailBookingServiceById(this.$route.params.id);
    window.scrollTo(0, 0);
  },
};
</script>