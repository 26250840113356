import $axios from "../../services/apinasmoconet.js";
const state = () => ({
    loading: false,
    //get car by seo
    form_booking_service: {
        name: "",
        email: "",
        phone: "",
        address: "",
        gender: "",
        birth: new Date(),
        province: "",
        province_name: "",
        city: "",
        city_name: "",
        car_category: "",
        car_category_name: "",
        car_model: "",
        car_model_name: "",
        car_transmission: "",
        car_type: "",
        car_type_name: "",
        police_number: "",
        build_year: "",
        kota_cabang: "",
        kota_cabang_name: "",
        cabang_id: "",
        cabang_id_name: "",
        car_km: "",
        service_type: "",
        service_ke: "",
        appointment_date: new Date(),
        appointment_time: "",
        note: "",
        promotion_code: "",
        token: "",
        channel: "booking-service"
    },
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.form_booking_service = {
                name: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                birth: new Date(),
                province: "",
                province_name: "",
                city: "",   
                city_name: "",
                car_category: "",
                car_category_name: "",
                car_model: "",
                car_model_name: "",
                car_transmission: "",
                car_type: "",
                car_type_name: "",
                police_number: "",
                build_year: "",
                kota_cabang: "",
                kota_cabang_name: "",
                cabang_id: "",
                cabang_id_name: "",
                car_km: "",
                service_type: "",
                service_ke: "3",
                appointment_date: new Date(),
                appointment_time: "",
                note: "",
                promotion_code: "",
                token: "",
                channel: "booking-service"
            }
    },
    CLEAR_FORM(state) {
        state.form_booking_service = {
            name: "",
            email: "",
            phone: "",
            address: "",
            gender: "",
            birth: new Date(),
            province: "",
            province_name: "",
            city: "",
            city_name: "",
            car_category: "",
            car_category_name: "",
            car_model: "",
            car_model_name: "",
            car_transmission: "",
            car_type: "",
            car_type_name: "",
            police_number: "",
            build_year: "",
            kota_cabang: "",
            kota_cabang_name: "",
            cabang_id: "",
            cabang_id_name: "",
            car_km: "",
            service_type: "",
            service_ke: "3",
            appointment_date: new Date(),
            appointment_time: "",
            note: "",
            promotion_code: "",
            token: "",
            channel: "booking-service"
        }
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    insertDataBookingService({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`bookingonlinenet.php`, {
                name: state.form_booking_service.name,
                email: state.form_booking_service.email,
                phone: state.form_booking_service.phone,
                address: state.form_booking_service.address,
                gender: state.form_booking_service.gender,
                birth: state.form_booking_service.birth,
                province: state.form_booking_service.province,
                city: state.form_booking_service.city_name,
                car_category: state.form_booking_service.car_category,
                car_model: state.form_booking_service.car_model_name,
                car_type: state.form_booking_service.car_type,
                police_number: state.form_booking_service.police_number,
                build_year: state.form_booking_service.build_year,
                cabang_id: state.form_booking_service.cabang_id_name,
                car_km: state.form_booking_service.car_km,
                service_type: state.form_booking_service.service_type,
                service_ke: state.form_booking_service.service_ke,
                appointment_date: state.form_booking_service.appointment_date,
                appointment_time: state.form_booking_service.appointment_time,
                note: state.form_booking_service.note,
                promotion_code: state.form_booking_service.promotion_code,
                token: state.form_booking_service.token,
                channel: state.form_booking_service.channel
            })
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (state.form_booking_service.token != "") {
                        commit('CLEAR_FORM')
                    }
                    else if (state.form_booking_service.token == "") {
                        console.log(response);
                        commit('CLEAR_FORM')
                        //remove localstorage
                        localStorage.removeItem('token');
                        localStorage.removeItem('sharedPreference');
                        //set localstorage
                        localStorage.setItem('token', response.data.data.login.token)
                        localStorage.setItem('sharedPreference', JSON.stringify(response.data.data.login));
                        commit('SET_TOKEN', response.data.data.login.token, {
                            root: true
                        })
                        commit('SET_SHARED_PREFERENCE', response.data.data.login, {
                            root: true
                        })
                    }
                    resolve(response.data)
                }).catch(error => console.log(error, 'error'))
                /*.catch((error) => {
                    console.log(error);
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 400) {
                        commit('SET_ERRORS', error.response.data.message.alert, {
                            root: true
                        })
                    }
                    if (error.response.data.code == 422) {
                        commit('SET_ERRORS', error.response.data.message, {
                            root: true
                        })
                    }
                    reject(error)
                })*/
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
