import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    // car for trade in
    brand: [],
    brand_model: [],
    brand_type: [],
    brand_year: [],

    form_trade_in: {
        // detail info profile 
        name: "",
        email: "",
        phone: "",
        address: "",
        gender: "",
        birth: new Date(),
        province: "",
        province_name: "",
        city: "",
        city_name: "",

        //for trade in
        brand: "",
        brand_name: "",
        brand_model: "",
        brand_model_name: "",
        brand_year: "",
        brand_type: "",
        brand_type_name: "",

        // detail car 
        car_category: "",
        car_category_name: "",
        car_model: "",
        car_model_name: "",
        car_transmission: "",
        car_type: "",
        car_type_name: "",
        kota_cabang: "",
        kota_cabang_name: "",
        cabang_id: "",
        cabang_id_name: "",
        token: "",
        channel: "trade-in"
    },
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.brand = [],
            state.brand_model = [],
            state.brand_type = [],
            state.brand_year = [],
            state.form_trade_in = {
                // detail info profile 
                name: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                birth: new Date(),
                province: "",
                province_name: "",
                city: "",
                city_name: "",

                //for trade in
                brand: "",
                brand_name: "",
                brand_model: "",
                brand_model_name: "",
                brand_year: "",
                brand_type: "",
                brand_type_name: "",

                // detail car 
                car_category: "",
                car_category_name: "",
                car_model: "",
                car_model_name: "",
                car_transmission: "",
                car_type: "",
                car_type_name: "",
                kota_cabang: "",
                kota_cabang_name: "",
                cabang_id: "",
                cabang_id_name: "",
                token: "",
                channel: "trade-in"
            }
    },
    CLEAR_FORM(state) {
        state.form_trade_in = {
            // detail info profile 
            name: "",
            email: "",
            phone: "",
            address: "",
            gender: "",
            birth: new Date(),
            province: "",
            province_name: "",
            city: "",
            city_name: "",

            //for trade in
            brand: "",
            brand_name: "",
            brand_model: "",
            brand_model_name: "",
            brand_year: "",
            brand_type: "",
            brand_type_name: "",

            // detail car 
            car_category: "",
            car_category_name: "",
            car_model: "",
            car_model_name: "",
            car_transmission: "",
            car_type: "",
            car_type_name: "",
            kota_cabang: "",
            kota_cabang_name: "",
            cabang_id: "",
            cabang_id_name: "",
            token: "",
            channel: "trade-in"
        }
    },
    ASSIGN_DATA_BRAND(state, payload) {
        state.brand = payload
    },
    ASSIGN_DATA_BRAND_MODEL(state, payload) {
        state.brand_model = payload
    },
    ASSIGN_DATA_BRAND_TYPE(state, payload) {
        state.brand_type = payload
    },
    ASSIGN_DATA_BRAND_YEAR(state, payload) {
        state.brand_year = payload
    }
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getBrand({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`brand`)
                .then((response) => {
                    commit('ASSIGN_DATA_BRAND', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getBrandModel({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`brandmodel`, {
                brand_id: payload.brand_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_BRAND_MODEL', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getBrandYear({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`brandyear`, {
                brand_id: payload.brand_id,
                brand_model_id: payload.brand_model_id,
            })
                .then((response) => {
                    commit('ASSIGN_DATA_BRAND_YEAR', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getBrandType({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`brandtype`, {
                brand_id: payload.brand_id,
                brand_model_id: payload.brand_model_id,
                brand_model_year: payload.brand_model_year
            })
                .then((response) => {
                    commit('ASSIGN_DATA_BRAND_TYPE', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    insertDataTradeIn({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`tradein`, state.form_trade_in
            )
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (state.form_trade_in.token != "") {
                        commit('CLEAR_FORM')
                    }
                    else if (state.form_trade_in.token == "") {
                        commit('CLEAR_FORM')
                        //remove localstorage
                        localStorage.removeItem('token');
                        localStorage.removeItem('sharedPreference');
                        //set localstorage
                        localStorage.setItem('token', response.data.data.login.token)
                        localStorage.setItem('sharedPreference', JSON.stringify(response.data.data.login));
                        commit('SET_TOKEN', response.data.data.login.token, {
                            root: true
                        })
                        commit('SET_SHARED_PREFERENCE', response.data.data.login, {
                            root: true
                        })
                    }
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 400) {
                        commit('SET_ERRORS', error.response.data.message.alert, {
                            root: true
                        })
                    }
                    reject(error)
                })
        })
    },

};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
