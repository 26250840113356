<template>
  <div id="app">
    <div class="site-wrap" id="main-layout" v-if="$route.meta.mainLayout">
      <app-header />
      <app-sticky />
      <transition name="slide-fade">
        <router-view :key="$route.fullPath"> </router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
      <app-footer />
      <app-copyright />
    </div>
    <div class="site-wrap" id="order-layout" v-if="$route.meta.orderLayout">
      <app-header />
      <app-sticky />
      <transition name="slide-fade">
        <router-view :key="$route.fullPath"> </router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
    </div>
    <div class="site-wrap" id="member-layout" v-if="$route.meta.memberLayout">
      <app-header />
      <app-sticky />
      <transition name="slide-fade">
        <router-view :key="$route.fullPath"> </router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
      <app-copyright />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Copyright from "@/components/Copyright.vue";
import Sticky from "@/components/Sticky.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-copyright": Copyright,
    "app-sticky": Sticky,
  },
  computed: {
    ...mapGetters(["isAuth", "isSharedPreference"]),
    ...mapState("home", {
      message_form_visitor: (state) => state.message_form_visitor,
      form_visitor: (state) => state.form_visitor,
      message_token: (state) => state.message_token,
    }),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  watch: {
    isIdle: {
      handler(newValue) {
        if (!newValue) {
          if (this.isAuth) {
            this.cekToken({
              email: this.isSharedPreference.member_email,
              token: this.isSharedPreference.token,
            });
            if (this.message_token == "failed") {
              this.logout();
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$Progress.finish();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("path/to/service-worker.js")
        .then((reg) => {
          console.log("Registration succesful, scope: " + reg.scope);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    $(".click-hide").on("click", function () {
      $(".hs-has-mega-menu").removeClass(
        "hs-event-prevented hs-mega-menu-opened"
      );
      $(".hs-has-sub-menu").removeClass(
        "hs-event-prevented hs-sub-menu-opened"
      );
    });
  },
  methods: {
    ...mapActions("home", ["Set_Visitor", "Send_Visitor", "cekToken"]),
    getVisitor() {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.form_visitor.date = moment(new Date()).format("YYYY-MM-DD");
          this.form_visitor.ip_address = ip;
          this.Send_Visitor();
        });
    },
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    async logout() {
      try {
        localStorage.removeItem("token");
        localStorage.removeItem("sharedPreference");
        this.$store.state.token = localStorage.getItem("token");
        this.$router.push({
          name: "Home",
        });
        swal.fire({
          title: "Sesi Anda telah Expired",
          icon: "error",
          confirmButtonColor: "#27ae60",
          confirmButtonText: "OK!",
        });
      } catch (error) {
        throw error;
      }
    },
  },
  created() {
    this.getVisitor();
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish();
    });

    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}

.info-box-cus {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  position: relative;
}
</style>