<template>
    <div>
      <section
        class="intro-md"
        data-background="assets/img/bg-7.png"
        style="background-image: url('assets/img/bg-7.png')"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="block-text pt-lg-5">
                <p class="section-subheading">LEAD SALES</p>
                <h2 class="section-heading">
                  Persetujuan Pelindungan Data Pribadi
                </h2>
                <p class="section-description-sm mb-0">
                  Silahkan isikan identitas anda.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <section class="formulir-section section-lg pt-lg-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <form method="post" action="javascript:void(0)" class="form-style">
                <div class="card">
                  <!-- Data Pribadi  -->
                  <div class="card-header"><span>Data Pribadi</span></div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-10 m-auto">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="control-label">Nama</label>
                              <input
                                v-model.trim="form_lead_sales.name"
                                :class="{
                                  'is-invalid': $v.form_lead_sales.name.$error,
                                  'is-valid': !$v.form_lead_sales.name.$error && form_lead_sales.name != '',
                                }"
                                type="text"
                                class="form-control"
                                placeholder="Isikan dengan nama sesuai KTP"
                              />

                              <div
                                class="invalid-feedback"
                                v-if="!$v.form_lead_sales.name.required"
                              >
                                *Masukkan Nama Lengkap!.
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label class="control-label">No. Whatsapp Aktif</label>
                              <input
                                v-model.trim="form_lead_sales.phone"
                                :class="{
                                  'is-invalid': $v.form_lead_sales.phone.$error,
                                  'is-valid': !$v.form_lead_sales.phone.$error && form_lead_sales.phone != '',
                                }"
                                :disabled="isAuth"
                                type="text"
                                name=""
                                class="form-control"
                                placeholder="Isikan dengan No. Whatsapp Aktif Anda"
                                @keypress="isNumber($event)"
                              />
                              <div
                                class="invalid-feedback"
                                v-if="!$v.form_lead_sales.phone.required"
                              >
                                *Masukkan No Telp!.
                              </div>
                              <div
                                class="invalid-feedback"
                                v-if="!$v.form_lead_sales.phone.minLength"
                              >
                                *No. Telp minimal 10 digit!.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-10 m-auto">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="custom-control custom-checkbox">
                              <input
                                class="custom-control-input"
                                type="checkbox"
                                id="agree1"
                                v-model="form_lead_sales.agree1"
                              />
                              <label class="custom-control-label" style="text-align:justify" for="agree1"
                                  >Saya telah membaca dan memahami syarat dan ketentuan terkait data pribadi yang diakses dalam www.nasmoco.co.id ("<b>Kebijakan Privasi</b>"), dan oleh karenanya <a href="/info/kebijakan-privasi" target="_blank"><u><b> saya dengan ini menyatakan dengan tegas dan tanpa paksaan bahwa saya memberikan persetujuan serta terikat dengan seluruh syarat dan ketentuan yang diatur di dalam Kebijakan Privasi tersebut kepada Nasmoco Group</b></u></a></label
                              >
                            </div>
                            <br><br><br>
                            <div class="custom-control custom-checkbox">
                              <input
                                class="custom-control-input"
                                type="checkbox"
                                id="agree2"
                                v-model="form_lead_sales.agree2"
                              />
                              <label class="custom-control-label" style="text-align:justify" for="agree2"
                                  >Seluruh dokumen, data, dan/atau informasi pribadi yang saya sampaikan pada formulir ini adalah benar, sesuai dengan aslinya dan dapat dipertanggungjawabkan, saya memberikan wewenang kepada Nasmoco Group untuk melakukan pemrosesan atas dokumen, data, dan/atau informasi pribadi tersebut sesuai dengan ketentuan yang diatur dalam Kebijakan Privasi.</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-10 m-auto">  
                        <div class="text-center">
                          <button
                            :disabled="$v.form_lead_sales.$invalid"
                            v-if="!isSave"
                            type="submit"
                            class="
                              btn btn-padd btn-width
                              text-center
                              btn-yellow
                              mt-4
                            "
                            @click.prevent="submit"
                          >
                            SUBMIT <i class="fa fa-arrow-right ml-2"></i>
                          </button>
                          <button
                            v-if="isSave"
                            type="submit"
                            class="
                              btn btn-padd btn-width
                              text-center
                              btn-yellow
                              mt-4
                            "
                          >
                            <span class="spinner-border spinner-border-sm"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import { required, minLength } from "vuelidate/lib/validators";
  export default {
    name: "LeadSales",
    metaInfo() {
      return {
        title: "Lead Sales - Dealer Toyota Semarang",
        meta: [
          {
            name: "description",
            content:
              "Dealer Toyota Semarang",
          },
          {
            name: "keywords",
            content:
              "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
          },
        ],
      };
    },
    data() {
      return {
        ipAds: null, // State untuk IP address
      };
    },
    created() {
      if (this.isAuth) {
        this.form_lead_sales.name = this.isSharedPreference.full_name;
        this.form_lead_sales.phone = this.isSharedPreference.phone;
        this.form_lead_sales.agree1 = this.isSharedPreference.agree1;
        this.form_lead_sales.agree2 = this.isSharedPreference.agree2;
      }
      this.getIpAddress();
      window.scrollTo(0, 0);
    },
    validations: {
      form_lead_sales: {
        name: { required },
        phone: { required, minLength: minLength(10) },
        agree1: { required },  // Validasi untuk checkbox
        agree2: { required },  // Validasi untuk checkbox
      },
    },
    watch: {
      form_lead_sales: {
        handler(newValue) {},
        deep: true,
      },
    },
    mounted() {
      $("[data-background]").each(function () {
        $(this).css(
          "background-image",
          "url(" + $(this).attr("data-background") + ")"
        );
      });
    },
    computed: {
      ...mapState(["isSave"]),
      ...mapGetters(["isAuth", "isToken", "isSharedPreference"]),
      ...mapState(["errors"]),
      ...mapState("lead_sales", {
        form_lead_sales: (state) => state.form_lead_sales,
      }),
      // isSubmitDisabled() {
      //   return !this.form_lead_sales.agree1 || !this.form_lead_sales.agree2 || form_lead_sales.$invalid;
      // },
    },
    methods: {
      getIpAddress() {
        axios.get('https://api.ipify.org?format=json')
          .then((response) => {
            // this.ipAddress = response.data.ip;
            this.form_lead_sales.ipAds = response.data.ip;
          })
          .catch((error) => {
            console.error("IP address could not be fetched", error);
          });
      },
      ...mapMutations(["CLEAR_ERRORS"]),
      ...mapActions("lead_sales", ["insertLeadSales", "Clear_State"]),
      // keypres untuk input float data type
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      //insert booking service
      submit() {
        this.$Progress.start();
        
        this.$v.$touch(); // Ini untuk memaksa validasi dijalankan
        if (this.$v.$invalid) {
          // Jika form tidak valid, hentikan submit
          return;
        }

        // Siapkan data yang akan dikirim, termasuk IP address
        // const leadSalesData = {
        //   ...this.form_lead_sales,  // Data form
        //   ipAddress: this.ipAddress, // Tambahkan IP address
        // };

        this.insertLeadSales()
          .then((res) => {
            this.CLEAR_ERRORS();
            swal.fire({
              title: "Data Anda berhasil disimpan",
              icon: "success",
              confirmButtonColor: "#27ae60",
              confirmButtonText: "OK!",
            });

            this.$router.push({
              name: "Home",
            });
            this.$Progress.finish();
          })
          .catch((error) => {
            swal.fire({
              text: this.errors,
              icon: "error",
              confirmButtonColor: "#27ae60",
              confirmButtonText: "OK!",
            });
            this.$Progress.fail();
          });
      },
    },
    destroyed() {
      this.Clear_State();
    },
  };
  </script>