<template>
  <div>
    <section class="intro-home">
      <div v-if="loading_slider" class="intro-slider-container">
        <b-skeleton-img no-aspect></b-skeleton-img>
        
      </div>

      <div v-else class="intro-slider-container">
        <!-- desktop  -->
        <div
          class="owl-carousel owl-theme intro-slider d-none d-md-block"
          v-if="loaded_slider_desktop"
        >
          <div v-if="loading_slider" class="owl-item">
            <b-skeleton-img></b-skeleton-img>
          </div>
          <div
            v-else
            class="owl-item"
            v-for="(row_desktop, index_desktop) in owl_slider_desktop"
            :key="index_desktop"
          >
            <img
              @click="goToLinkSlider(row_desktop.link)"
              v-if="row_desktop.type == 'desktop'"
              :data-src="row_desktop.image"
              class="img-fluid lazyload img-slider"
            />
          </div>
          <img
              @click="goToLinkSlider('https://toss.nasmoco.co.id')"
              data-src="assets/img/web_toss_min.jpg"
              class="img-fluid lazyload img-slider"
            />
        </div>

        <!-- mobile  -->
        <div
          class="owl-carousel owl-theme intro-slider d-block d-md-none"
          v-if="loaded_slider_mobile"
        >
          <div
            class="owl-item"
            v-for="(row_mobile, index_mobile) in owl_slider_mobile"
            :key="index_mobile"
          >
            <img
              @click="goToLinkSlider(row_desktop.link)"
              v-if="row_mobile.type == 'mobile'"
              :data-src="row_mobile.image"
              class="img-fluid lazyload img-slider"
            />
          </div>
        </div>

        <div class="wrap-nav-slider">
          <div class="nav-slider trans-ease-in-out intro-slider-prev">
            <span class="ion-chevron-left"></span>
          </div>
          <div class="nav-slider trans-ease-in-out intro-slider-next">
            <span class="ion-chevron-right"></span>
          </div>
        </div>
      </div>
      
      <div class="journey container">
        <p class="section-description-sm"></p>
        <div class="box-journey card rounded-10">
          <form class="form-style">
            <div class="wrap-form trans-ease-in-out">
              <v-select
                class="vselect-custome trans"
                :options="question.question_1"
                label="question"
                placeholder="Jenis Mobil yang Anda Idamkan"
                :clearable="false"
                @input="(val) => setValIDQuestion1(val)"
              >
                <template slot="no-options">Pertanyaan tidak ada</template>
                <template slot="option" slot-scope="option">{{
                  option.question
                }}</template>
              </v-select>
            </div>
            <div class="wrap-form trans-ease-in-out">
              <v-select
                class="vselect-custome trans"
                :options="question.question_2"
                label="question"
                placeholder="Pertimbangan Dalam Membeli Mobil"
                :clearable="false"
                @input="(val) => setValIDQuestion2(val)"
              >
                <template slot="no-options">Pertanyaan tidak ada</template>
                <template slot="option" slot-scope="option">{{
                  option.question
                }}</template>
              </v-select>
            </div>
            <div
              class="wrap-form trans-ease-in-out"
              v-if="find_car.question_1 != '' && find_car.question_1 == 5"
            >
              <v-select
                class="vselect-custome trans"
                :options="question.question_3"
                label="question"
                placeholder="Jenis Mobil Bisnis"
                :clearable="false"
                @input="(val) => setValIDQuestion3(val)"
              >
                <template slot="no-options">Pertanyaan tidak ada</template>
                <template slot="option" slot-scope="option">{{
                  option.question
                }}</template>
              </v-select>
            </div>
            <button
              :disabled="$v.find_car.$invalid"
              id="submit"
              class="btn btn-yellow shadow-none d-flex align-items-center"
              @click.prevent="findDreamCar"
            >
              <span>FIND</span> <i class="fa fa-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>
    </section>

    <section class="services pt-5">
      <div v-if="loading_icons" class="container">
        <div class="flex-4-column">
          <div class="flex-col">
            <!-- contact-us  -->
            <div class="items">
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
            </div>
          </div>
          <div class="flex-col">
            <!-- contact-us  -->
            <div class="items">
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
            </div>
          </div>
          <div class="flex-col">
            <!-- contact-us  -->
            <div class="items">
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
            </div>
          </div>
          <div class="flex-col">
            <!-- contact-us  -->
            <div class="items">
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
              <p class="section-description-md">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div v-else class="container">
        <div class="flex-4-column">
          <div class="flex-col" v-for="(row, index) in icons" :key="index">
            <!-- contact-us  -->
            <router-link
              v-if="row.url == 'contact-us'"
              :to="{
                name: 'Contact_us',
              }"
            >
              <div class="items">
                <img :data-src="row.image" class="img-fluid lazyload icon" />
                <p class="section-description-md">{{ row.title }}</p>
              </div>
            </router-link>

            <router-link
              v-if="row.url == 'comparison'"
              :to="{
                name: 'Compare_car',
              }"
            >
              <div class="items">
                <img :data-src="row.image" class="img-fluid lazyload icon" />
                <p class="section-description-md">{{ row.title }}</p>
              </div>
            </router-link>

            <router-link
              v-if="row.url == 'credit-simulation'"
              :to="{
                name: 'Credit_simulation',
              }"
            >
              <div class="items">
                <img :data-src="row.image" class="img-fluid lazyload icon" />
                <p class="section-description-md">{{ row.title }}</p>
              </div>
            </router-link>

            <router-link
              v-if="row.url == 'booking-service'"
              :to="{
                name: 'Booking_service',
              }"
            >
              <div class="items">
                <img :data-src="row.image" class="img-fluid lazyload icon" />
                <p class="section-description-md">{{ row.title }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <hr />
      </div>
    </section>

    <section class="dream-car section-sm pb-lg-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-6" v-if="loading_carcategory">
            <div class="box-section-title">
              <b-skeleton animation="wave" width="60%"></b-skeleton>
              <p class="section-description-md">
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="80%"></b-skeleton>
              </p>
            </div>
          </div>
          <div class="col-lg-6" v-else>
            <div class="box-section-title">
              <h2 class="section-heading"><span>Find your</span> Dream Car</h2>
              <p class="section-description-md">
                Dapatkan informasi lengkap dan terkini mengenai ragam pilihan
                mobil idaman Anda.
              </p>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="dream-car-box">
              <ul
                v-if="loading_carcategory"
                class="nav nav-tabs nav-tabs-category"
                id="myTab"
                role="tablist"
              >
                <li
                  class="nav-item"
                  v-for="(row, index) in car_category"
                  :key="index"
                >
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href=""
                    role="tab"
                    aria-selected="true"
                  >
                    <b-skeleton animation="wave" width="40%"></b-skeleton
                  ></a>
                </li>
              </ul>
              <ul
                v-else
                class="nav nav-tabs nav-tabs-category"
                id="myTab"
                role="tablist"
              >
                <li
                  class="nav-item"
                  v-for="(row, index) in car_category"
                  :key="index"
                >
                  <a
                    :class="{
                      active: index == 0,
                    }"
                    class="nav-link"
                    :id="row.category_name"
                    data-toggle="tab"
                    :href="row.id_category"
                    @click="getCarList(row.category_name)"
                    role="tab"
                    :aria-controls="row.category_name"
                    aria-selected="true"
                    >{{ row.category_name }}</a
                  >
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" role="tabpanel">
                  <div v-if="loading_car_name" class="dream-car-content">
                    <div
                      class="nav nav-dream-car-product"
                      aria-orientation="vertical"
                    >
                      <a class="nav-link">
                        <b-skeleton-img></b-skeleton-img>
                      </a>
                    </div>

                    <div
                      class="tab-content nav-dream-car-preview"
                      id="v-pills-tabContent"
                    >
                      <div
                        class="tab-pane fade show active"
                        id="car-product-4"
                        role="tabpanel"
                        aria-labelledby="dream-car-p4"
                      >
                        <div class="row">
                          <div class="col-lg-5 dream-car-preview-info">
                            <h2 class="section-heading">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                            </h2>
                            <p class="section-description-xs">
                              <b-skeleton
                                animation="wave"
                                width="100%"
                              ></b-skeleton>
                            </p>

                            <hr />

                            <h2 class="section-heading">
                              <b-skeleton
                                animation="wave"
                                width="85%"
                              ></b-skeleton>
                            </h2>
                            <p class="section-description-xs">
                              <b-skeleton
                                animation="wave"
                                width="100%"
                              ></b-skeleton>
                            </p>

                            <hr />
                          </div>

                          <div class="col-lg-7">
                            <b-skeleton-img></b-skeleton-img>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="dream-car-content">
                    <div v-if="car_name === 'no data recorded'">
                      <div class="not-found-items">
                        <svg
                          viewBox="0 0 126 128"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.2612 60.4219H49.7881C51.344 60.4219 52.3813 59.3846 52.3813 57.8287V39.1577C52.3813 37.6017 51.344 36.5645 49.7881 36.5645H14.2612C12.7053 36.5645 11.668 37.6017 11.668 39.1577V57.8287C11.668 59.1253 12.7053 60.4219 14.2612 60.4219ZM16.8544 41.7509H47.1949V55.2355H16.8544V41.7509Z"
                          />
                          <path
                            d="M61.4604 60.4219H95.95C97.5059 60.4219 98.5432 59.3846 98.5432 57.8287V39.1577C98.5432 37.6017 97.5059 36.5645 95.95 36.5645H61.4604C59.9045 36.5645 58.8672 37.6017 58.8672 39.1577V57.8287C58.8672 59.1253 59.9045 60.4219 61.4604 60.4219ZM64.0536 41.7509H93.3568V55.2355H64.0536V41.7509Z"
                          />
                          <path
                            d="M13.4827 22.3021H18.6691C21.003 22.3021 23.0775 20.2276 23.0775 17.8937V12.7073C23.0775 10.1141 21.003 8.29883 18.6691 8.29883H13.4827C11.1488 8.29883 9.07422 10.3734 9.07422 12.7073V17.8937C9.07422 20.4869 10.8895 22.3021 13.4827 22.3021ZM14.2606 13.4852H17.8911V17.375H14.2606V13.4852Z"
                          />
                          <path
                            d="M30.3381 22.3021H35.5245C37.8584 22.3021 39.933 20.2276 39.933 17.8937V12.7073C39.933 10.1141 37.8584 8.29883 35.5245 8.29883H30.3381C28.0043 8.29883 25.9297 10.3734 25.9297 12.7073V17.8937C25.9297 20.4869 28.0043 22.3021 30.3381 22.3021ZM31.1161 13.4852H34.7466V17.375H31.1161V13.4852Z"
                          />
                          <path
                            d="M46.6752 17.8934H98.7986C100.355 17.8934 101.392 16.8562 101.392 15.3002C101.392 13.7443 100.355 12.707 98.7986 12.707H46.6752C45.1193 12.707 44.082 13.7443 44.082 15.3002C44.082 16.8562 45.1193 17.8934 46.6752 17.8934Z"
                          />
                          <path
                            d="M74.9428 66.9043H10.1127C8.55681 66.9043 7.51953 67.9416 7.51953 69.4975C7.51953 71.0534 8.55681 72.0907 10.1127 72.0907H74.9428C76.4988 72.0907 77.536 71.0534 77.536 69.4975C77.536 68.2009 76.2394 66.9043 74.9428 66.9043Z"
                          />
                          <path
                            d="M54.9752 80.1299H10.1127C8.55681 80.1299 7.51953 81.1672 7.51953 82.7231C7.51953 84.279 8.55681 85.3163 10.1127 85.3163H55.2345C56.7904 85.3163 57.8277 84.279 57.8277 82.7231C57.5684 81.4265 56.5311 80.1299 54.9752 80.1299Z"
                          />
                          <path
                            d="M54.9752 93.3555H10.1127C8.55681 93.3555 7.51953 94.3928 7.51953 95.9487C7.51953 97.5046 8.55681 98.5419 10.1127 98.5419H55.2345C56.7904 98.5419 57.8277 97.5046 57.8277 95.9487C57.5684 94.3928 56.5311 93.3555 54.9752 93.3555Z"
                          />
                          <path
                            d="M124.992 116.435L110.211 92.5774V9.33553C110.211 4.14913 106.062 0 100.876 0H9.33553C4.14913 0 0 4.14913 0 9.33553V102.95C0 106.84 3.37117 110.211 7.77961 110.211H64.3115L60.4217 116.435C59.125 118.509 58.8657 121.362 60.1623 123.437C61.4589 125.77 63.7928 127.067 66.386 127.067H119.028C121.621 127.067 123.955 125.77 125.252 123.437C126.289 121.103 126.289 118.509 124.992 116.435ZM9.33553 5.18641H100.876C103.21 5.18641 105.025 7.00165 105.025 9.33553V25.6727H5.18641V9.33553C5.18641 7.00165 7.00165 5.18641 9.33553 5.18641ZM7.77961 105.025C6.48301 105.025 5.44573 103.987 5.44573 102.95V30.5998H105.284V84.2791L98.5418 73.9063C97.2452 71.8318 94.9113 70.5352 92.5774 70.5352C90.2435 70.5352 87.9096 71.8318 86.613 73.9063L67.4233 105.025H7.77961ZM120.584 120.843C120.325 121.103 120.065 121.881 119.028 121.881H66.386C65.3487 121.881 64.8301 121.103 64.8301 120.843C64.5708 120.584 64.3115 119.806 64.8301 119.028L91.2808 76.4995C91.7994 75.7216 92.5774 75.7216 92.8367 75.7216C93.096 75.7216 93.874 75.7216 94.3926 76.4995L120.843 118.769C121.103 119.806 120.843 120.584 120.584 120.843Z"
                          />
                          <path
                            d="M95.4293 83.502H89.9836C88.9463 83.502 87.909 84.0206 87.131 84.7986C86.3531 85.5765 86.0938 86.6138 86.0938 87.9104L87.6497 101.654C87.909 103.729 89.4649 105.285 91.5395 105.285H93.8734C95.9479 105.285 97.7632 103.729 97.7632 101.654L99.3191 87.6511C99.3191 86.6138 99.0598 85.5765 98.2818 84.7986C97.5038 84.0206 96.4666 83.502 95.4293 83.502ZM92.8361 100.098L91.5395 88.6884H94.1327L92.8361 100.098Z"
                          />
                          <path
                            d="M92.5753 107.618C89.2041 107.618 86.3516 110.211 86.3516 113.842C86.3516 117.213 89.2041 120.066 92.5753 120.066C95.9464 120.066 98.7989 117.213 98.7989 113.842C98.7989 110.211 96.2057 107.618 92.5753 107.618ZM92.5753 114.879C92.0566 114.879 91.538 114.36 91.538 113.842C91.538 113.323 92.0566 112.805 92.5753 112.805C93.0939 112.805 93.6125 113.323 93.6125 113.842C93.6125 114.36 93.3532 114.879 92.5753 114.879Z"
                          />
                        </svg>
                        <h3>Not Found Items</h3>
                      </div>
                    </div>
                    <div
                      class="nav nav-dream-car-product"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <a
                        v-for="(row, index) in car_name"
                        :key="index"
                        class="nav-link"
                        :class="{
                          active: index == 0,
                        }"
                        id="dream-car-p1"
                        data-toggle="pill"
                        :href="row.link_car_product"
                        role="tab"
                        :aria-controls="row.car_seo"
                        aria-selected="true"
                      >
                        <img
                          :data-src="row.image"
                          alt=""
                          class="img-fluid lazyloaded"
                          :src="row.image"
                        />
                        <p>{{ row.name }}</p>
                      </a>
                    </div>
                    <div
                      class="tab-content nav-dream-car-preview"
                      id="v-pills-tabContent"
                    >
                      <div
                        v-for="(row, index) in car_name"
                        :key="index"
                        :class="{
                          active: index == 0,
                          show: index_car_name == 0,
                        }"
                        class="tab-pane fade"
                        :id="row.car_seo"
                        role="tabpanel"
                        aria-labelledby="dream-car-p1"
                      >
                        <div class="row">
                          <div class="col-lg-5 dream-car-preview-info">
                            <h2 class="section-heading">{{ row.name }}</h2>
                            <p class="section-description-xs">
                              {{ row.preview_text }}
                            </p>
                            <hr />
                            <p
                              class="section-description-sm font-weight-bold mb-1"
                            >
                              MULAI DARI
                            </p>
                            <div class="price-display-1">
                              <div v-if="row.price_disc != 0" class="box-price">
                                <del>{{ row.price_otr | currency }}</del>
                                <p class="price bg-price">
                                  {{ row.price_disc | currency }}
                                </p>
                              </div>
                              <div v-else class="box-price">
                                <p class="price bg-price">
                                  {{ row.price_otr | currency }}
                                </p>
                              </div>
                              <a
                                @click.prevent="toDetailCar(row.car_seo)"
                                class="link"
                                >PREVIEW
                                <i class="fa fa-chevron-right text-accent"></i
                              ></a>
                            </div>

                            <hr />

                            <div class="price-display-2">
                              <div class="box-price">
                                <p class="price">
                                  {{ row.harga_mulai | currency }}
                                </p>
                                <p class="section-description-xs mb-0">
                                  Harga Mulai
                                </p>
                              </div>
                              <div class="box-price">
                                <p class="price">
                                  {{ row.price_credit | currency }}
                                </p>
                                <p class="section-description-xs mb-0">
                                  per bulan | 60 Bulan Cicilan
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 dream-car-preview-product">
                            <img
                              :data-src="row.image"
                              alt=""
                              class="img-fluid ls-is-cached lazyloaded mb-5"
                              :src="row.image"
                            />

                            <a @click.prevent="toTestDrive(row)">
                              <div class="test-drive mt-5">
                                <span
                                  class="section-description-md font-weight-600"
                                  >Test Drive</span
                                >
                                <img
                                  data-src="assets/img/icon-test-drive.png"
                                  alt=""
                                  class="img-fluid lazyloaded"
                                  src="assets/img/icon-test-drive.png"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="recomended-product section-sm pb-lg-3">
      <div class="container">
        <div class="box-section-title" v-if="loading_recomendproduct">
          <b-skeleton animation="wave" width="60%"></b-skeleton>
          <div class="wrap-nav-slider">
            <div
              class="nav-slider product-slider-prev trans-ease-in-out recomended-slider-prev"
            >
              <span class="ion-chevron-left"></span>
            </div>
            <div
              class="nav-slider product-slider-next trans-ease-in-out recomended-slider-next"
            >
              <span class="ion-chevron-right"></span>
            </div>
          </div>
        </div>
        <div class="box-section-title" v-else>
          <h2 class="section-heading mb-0"><span>Recomended</span> Product</h2>
          <div class="wrap-nav-slider">
            <div
              class="nav-slider product-slider-prev trans-ease-in-out recomended-slider-prev"
            >
              <span class="ion-chevron-left"></span>
            </div>
            <div
              class="nav-slider product-slider-next trans-ease-in-out recomended-slider-next"
            >
              <span class="ion-chevron-right"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="product-slider-container" v-if="loading_recomendproduct">
          <div class="owl-carousel owl-theme product-slider">
            <div class="owl-item">
              <div class="card card-product">
                <div class="card-header">
                  <b-skeleton-img no-aspect></b-skeleton-img>
                </div>
                <div class="card-body">
                  <div class="wrap-padd">
                    <a href="">
                      <h2 class="heading">
                        <b-skeleton animation="wave" width="60%"></b-skeleton>
                      </h2>
                    </a>

                    <div class="price-display-1">
                      <b-skeleton animation="wave" width="100%"></b-skeleton>
                    </div>

                    <div class="excellence">
                      <div class="box-excellence">
                        <p class="label">
                          <b-skeleton animation="wave" width="70%"></b-skeleton>
                        </p>
                        <p class="value">
                          <b-skeleton animation="wave" width="60%"></b-skeleton>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <b-skeleton animation="wave" width="100%"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product-slider-container" v-else>
          <div
            class="owl-carousel owl-theme product-slider"
            v-if="loaded_recomended_product"
          >
            <div
              class="owl-item"
              v-for="(row, index) in owl_recomended"
              :key="index"
            >
              <div class="card card-product">
                <a @click.prevent="toDetailCar(row.car_seo)">
                  <div class="card-header">
                    <div class="ribbon-category">{{ row.category }}</div>
                    <img :data-src="row.image" class="img-fluid lazyload" />
                  </div>
                </a>
                <div class="card-body">
                  <div class="wrap-padd">
                    <a @click.prevent="toDetailCar(row.car_seo)">
                      <h2 class="heading">{{ row.name }}</h2>
                    </a>

                    <div class="price-display-1">
                      <div v-if="row.price_disc != 0" class="box-price">
                        <del>{{ row.price_otr | currency }}</del>
                        <p class="price">{{ row.price_disc | currency }}</p>
                      </div>
                      <div v-else class="box-price">
                        <p>
                          <strong>
                            {{ row.price_otr | currency }}
                          </strong>
                        </p>
                      </div>
                      <a @click.prevent="toDetailCar(row.car_seo)" class="link"
                        >DISCOVER <i class="fa fa-chevron-right text-accent"></i
                      ></a>
                    </div>

                    <div class="excellence">
                      <div
                        class="box-excellence"
                        v-for="(row_content, index_content) in recomendproduct[
                          index
                        ].featured"
                        :key="index_content"
                      >
                        <p class="label">{{ row_content.subtitle }}</p>
                        <p class="value">{{ row_content.title }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="price-display-2">
                    <div class="box-price">
                      <p class="price">{{ row.harga_mulai | currency }}</p>
                      <p class="section-description-xs mb-0">Harga Mulai</p>
                    </div>
                    <div class="box-price">
                      <p class="price">{{ row.price_credit | currency }}</p>
                      <p class="section-description-xs mb-0">
                        per bulan | 60 Bulan Cicilan
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <a
                    href=""
                    @click.prevent="toBuyToss(row)"
                    class="btn btn-block btn-lg btn-outline-dark font-weight-600 rounded"
                    >BUY A CAR
                  </a> <!--toBuyCar-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="spesial-offer section-sm pb-lg-3">
      <div class="container" v-if="loading_specialoffers">
        <div class="box-section-title text-center mb-0">
          <b-skeleton animation="wave" width="60%"></b-skeleton>
        </div>
        <div class="spesial-offer-slider-container">
          <div class="owl-carousel owl-theme spesial-offer-slider">
            <div class="items">
              <div class="overlay">
                <b-skeleton-img no-aspect></b-skeleton-img>
              </div>
            </div>
          </div>

          <div class="nav-dots">
            <div class="spesial-offer-slider-dots"></div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="box-section-title text-center mb-0">
          <h2 class="section-heading mb-0"><span>Special</span> Offer</h2>
        </div>

        <div class="spesial-offer-slider-container">
          <div
            class="owl-carousel owl-theme spesial-offer-slider"
            v-if="loaded_special_offers"
          >
            <div
              class="items"
              v-for="(row, index) in owl_special_offer"
              :key="index"
            >
              <div class="overlay">
                <img
                  @click="goToLinkSpecialOffer(row.url)"
                  :data-src="row.image"
                  alt=""
                  class="img-fluid lazyload"
                />
              </div>
            </div>
          </div>

          <div class="nav-dots">
            <div class="spesial-offer-slider-dots"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="serve-section section-md">
      <div class="container" v-if="loading_after_sale">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="box-section-title text-center">
              <b-skeleton animation="wave" width="50%"></b-skeleton>
              <p class="section-description-sm">
                <b-skeleton animation="wave" width="60%"></b-skeleton>
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-9 order-lg-last">
            <div class="flex-2-column">
              <div class="flex-col">
                <a href="" class="card card-style trans-ease-in-out">
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        data-src="assets/img/icon/icon-booking-service.png"
                        alt=""
                        class="img-fluid lazyload"
                      />
                    </div>
                    <h4 class="section-description">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                    </h4>
                    <p class="section-description-sm">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                      <b-skeleton animation="wave" width="50%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                    </p>
                  </div>
                </a>
              </div>
              <div class="flex-col">
                <a href="" class="card card-style trans-ease-in-out">
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        data-src="assets/img/icon/icon-booking-service.png"
                        alt=""
                        class="img-fluid lazyload"
                      />
                    </div>
                    <h4 class="section-description">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                    </h4>
                    <p class="section-description-sm">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                      <b-skeleton animation="wave" width="50%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                    </p>
                  </div>
                </a>
              </div>
              <div class="flex-col">
                <a href="" class="card card-style trans-ease-in-out">
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        data-src="assets/img/icon/icon-booking-service.png"
                        alt=""
                        class="img-fluid lazyload"
                      />
                    </div>
                    <h4 class="section-description">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                    </h4>
                    <p class="section-description-sm">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                      <b-skeleton animation="wave" width="50%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                    </p>
                  </div>
                </a>
              </div>
              <div class="flex-col">
                <a href="" class="card card-style trans-ease-in-out">
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        data-src="assets/img/icon/icon-booking-service.png"
                        alt=""
                        class="img-fluid lazyload"
                      />
                    </div>
                    <h4 class="section-description">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                    </h4>
                    <p class="section-description-sm">
                      <b-skeleton animation="wave" width="60%"></b-skeleton>
                      <b-skeleton animation="wave" width="50%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 pr-lg-0 order-lg-first">
            <div class="promotion-slider-container">
              <div class="owl-carousel owl-theme promotion-slider">
                <div class="owl-item">
                  <b-skeleton-img no-aspect></b-skeleton-img>
                </div>
              </div>

              <div class="wrap-nav-slider">
                <div class="nav-slider trans-ease-in-out promotion-slider-prev">
                  <span class="ion-chevron-left"></span>
                </div>
                <div class="nav-slider trans-ease-in-out promotion-slider-next">
                  <span class="ion-chevron-right"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="box-section-title text-center">
              <h2 class="section-heading">We Serve <span>You Better</span></h2>
              <p class="section-description-sm">
                Nasmoco Group menyediakan berbagai macam layanan terbaik yang
                siap memenuhi kebutuhan mobilitas Anda.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-9 order-lg-last">
            <div class="flex-2-column">
              <div
                class="flex-col"
                v-for="(row, index) in after_sale.icon"
                :key="index"
              >
                <router-link
                  v-if="row.link == 'booking-service'"
                  :to="{
                    name: 'Booking_service',
                  }"
                  class="card card-style trans-ease-in-out"
                >
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        :data-src="row.image"
                        alt=""
                        class="img-fluid lazyloaded"
                        :src="row.image"
                      />
                    </div>
                    <h4 class="section-description">{{ row.title }}</h4>
                    <p class="section-description-sm">
                      {{ row.teks }}
                    </p>
                  </div>
                </router-link>

                <router-link
                  v-else-if="row.link == 'sparepart-and-accesories'"
                  :to="{
                    name: 'Sparepart',
                  }"
                  class="card card-style trans-ease-in-out"
                >
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        :data-src="row.image"
                        alt=""
                        class="img-fluid lazyloaded"
                        :src="row.image"
                      />
                    </div>
                    <h4 class="section-description">{{ row.title }}</h4>
                    <p class="section-description-sm">
                      {{ row.teks }}
                    </p>
                  </div>
                </router-link>

                <a
                  v-else-if="
                    row.link == 'general-repair' || row.link == 'body-and-paint'
                  "
                  @click="goToService(row.link)"
                  class="card card-style trans-ease-in-out"
                >
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        :data-src="row.image"
                        alt=""
                        class="img-fluid lazyloaded"
                        :src="row.image"
                      />
                    </div>
                    <h4 class="section-description">{{ row.title }}</h4>
                    <p class="section-description-sm">
                      {{ row.teks }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 pr-lg-0 order-lg-first">
            <div class="promotion-slider-container">
              <div
                class="owl-carousel owl-theme promotion-slider"
                v-if="loaded_after_sale"
              >
                <div
                  class="owl-item"
                  v-for="(row, index) in owl_after_sale.banner"
                  :key="index"
                >
                  <img
                    @click="goToLinkBanner(row.url)"
                    :data-src="row.image"
                    alt=""
                    class="img-fluid lazyload"
                  />
                </div>
              </div>

              <div class="wrap-nav-slider">
                <div class="nav-slider trans-ease-in-out promotion-slider-prev">
                  <span class="ion-chevron-left"></span>
                </div>
                <div class="nav-slider trans-ease-in-out promotion-slider-next">
                  <span class="ion-chevron-right"></span>
                </div>

                <span class="section-description-xs"
                  >PROMOTIONAL BANNER SLIDE</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="highlight-blog section-md">
      <div class="container" v-if="loading_news">
        <div class="box-section-title-2">
          <b-skeleton animation="wave" width="100%"></b-skeleton>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header">
                <b-skeleton-img></b-skeleton-img>
              </div>
              <div class="card-body">
                <a href="">
                  <h2 class="section-description-lg">
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </h2>
                </a>
                <p class="section-description-sm">
                  <b-skeleton animation="wave" width="90%"></b-skeleton>
                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                  <b-skeleton animation="wave" width="90%"></b-skeleton>
                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header">
                <b-skeleton-img></b-skeleton-img>
              </div>
              <div class="card-body">
                <a href="">
                  <h2 class="section-description-lg">
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </h2>
                </a>
                <p class="section-description-sm">
                  <b-skeleton animation="wave" width="90%"></b-skeleton>
                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                  <b-skeleton animation="wave" width="90%"></b-skeleton>
                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-header">
                <b-skeleton-img></b-skeleton-img>
              </div>
              <div class="card-body">
                <a href="">
                  <h2 class="section-description-lg">
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </h2>
                </a>
                <p class="section-description-sm">
                  <b-skeleton animation="wave" width="90%"></b-skeleton>
                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                  <b-skeleton animation="wave" width="90%"></b-skeleton>
                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="box-section-title-2">
          <h2 class="section-heading-xs">NASMOCO HIGHLIGHT</h2>
        </div>

        <div class="row"> 
          <div
            class="col-lg-4 col-md-6"
            v-for="(row, index) in news"
            :key="index"
          >
            <div class="card card-style shadow-none">
              <router-link
                :to="{
                  name: 'News_read',
                  params: { link: row.seo },
                }"
              >
                <div class="card-header">
                  <img
                    :data-src="row.image"
                    alt=""
                    class="img-fluid lazyload"
                  />
                </div>
              </router-link>
              <div class="card-body">
                <router-link
                  :to="{
                    name: 'News_read',
                    params: { link: row.seo },
                  }"
                >
                  <h5>
                    <strong>
                      {{ row.title }}
                    </strong>
                  </h5>
                </router-link>
                <p class="section-description-sm mb-0">
                  {{ row.text_preview }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-center mt-4">
            <router-link
              :to="{ name: 'Promo' }"
              class="section-description-sm font-weight-600"
              >Discover All News <i class="fa fa-arrow-right ml-3"></i
            ></router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="container"><hr /></div>

    <section class="trusted-values section-sm">
      <div class="container" v-if="loading_values">
        <div class="row">
          <div class="col-lg-5 pr-lg-5">
            <div class="box-section-title">
              <h2 class="section-heading">
                <b-skeleton animation="wave" width="70%"></b-skeleton>
              </h2>
              <p class="section-description-sm">
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="60%"></b-skeleton>
                <b-skeleton animation="wave" width="80%"></b-skeleton>
              </p>
            </div>
          </div>

          <div class="col-lg-7 align-self-center">
            <div class="box-content flex-4-column">
              <div class="items flex-col">
                <b-skeleton-img></b-skeleton-img>
                <p class="section-description-sm">
                  <b-skeleton animation="wave" width="70%"></b-skeleton>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-else>
        <div class="row">
          <div class="col-lg-5 pr-lg-5">
            <div class="box-section-title">
              <h2 class="section-heading">
                Our Most <br /><span>Trusted Values</span>
              </h2>
              <p class="section-description-sm">
                Kami Menyediakan Layanan After Sale Terbaik untuk Menjamin
                Kenyamanan Mobil Anda. Kunjungi Link dibawah Ini untuk
                Mendapatkan Informasi Layanan After Sale Kami.
              </p>
            </div>
          </div>

          <div class="col-lg-7 align-self-center">
            <div class="box-content flex-4-column">
              <div
                class="items flex-col"
                v-for="(row, index) in values"
                :key="index"
              >
                <img :data-src="row.image" alt="" class="img-fluid lazyload" />
                <p class="section-description-sm">{{ row.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="near-us section-md pt-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-center text-lg-left">
            <div
              class="card-video bg-image center d-flex align-items-center justify-content-center"
              data-background="assets/img/cover-video.png"
            >
              <a
                href="https://www.youtube.com/watch?v=bLcB3go9IkA"
                class="popup-video"
              >
                <div class="play-button"><i class="fa fa-play"></i></div>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box-section-title">
              <h2 class="section-heading">
                <span>Lebih dekat</span> <br />dengan kami
              </h2>
              <p class="section-description-sm">
                Nasmoco Group kini hadir melalui 24 cabang Terdekat di Kota
                Anda.
              </p>

              <div class="find-call">
                <router-link
                  :to="{ name: 'Find_dealer' }"
                  class="btn btn-padd btn-yellow rounded px-4"
                  ><span>FIND DEALER</span>
                  <i class="fa fa-caret-right ml-3"></i
                ></router-link>
                <a
                  href="https://wa.me/628981260060"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp px-4"
                  ><i class="fab fa-whatsapp font-weight-400 mr-2"></i
                  >WHATSAPP</a
                >
                <!-- <a href="https://wa.me/628981260060" class="btn btn-padd call"><i class="fa fa-phone mr-2"></i> <span>0898 - 1260060</span></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Home",
  metaInfo() {
    return {
      title: "Nasmoco - Dealer Resmi Toyota Area Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {
      index_car_name: 0,
      // sliders
      loaded_slider_desktop: false,
      loaded_slider_mobile: false,
      owl_slider_desktop: [],
      owl_slider_mobile: [],

      // recomended product
      loaded_recomended_product: false,
      owl_recomended: [],

      // special offers
      loaded_special_offers: false,
      owl_special_offer: [],

      // after sale
      loaded_after_sale: false,
      owl_after_sale: [],
    };
  },
  validations() {
    if (this.find_car.question_1 == "5") {
      return {
        find_car: {
          question_1: { required },
          question_1_title: { required },
          question_2: { required },
          question_2_title: { required },
          question_3: { required },
          question_3_title: { required },
        },
      };
    } else {
      return {
        find_car: {
          question_1: { required },
          question_1_title: { required },
          question_2: { required },
          question_2_title: { required },
        },
      };
    }
  },
  created() {
    this.getIcons();
    this.getNews();
    this.getValues();
    this.getCategoryCar();
    this.getQuestion();
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState("home", {
      loading_slider: (state) => state.loading_slider,
      sliders: (state) => state.sliders,

      icons: (state) => state.icons,
      loading_icons: (state) => state.loading_icons,

      recomendproduct: (state) => state.recomendproduct,
      loading_recomendproduct: (state) => state.loading_recomendproduct,

      specialoffers: (state) => state.specialoffers,
      loading_specialoffers: (state) => state.loading_specialoffers,

      news: (state) => state.news,
      loading_news: (state) => state.loading_news,

      values: (state) => state.values,
      loading_values: (state) => state.loading_values,

      car_category: (state) => state.car_category,
      loading_carcategory: (state) => state.loading_carcategory,

      category: (state) => state.category,
      car_name: (state) => state.car_name,
      loading_car_name: (state) => state.loading_car_name,

      question: (state) => state.question,

      after_sale: (state) => state.after_sale,
      loading_after_sale: (state) => state.loading_after_sale,
    }),
    ...mapState("nasmoco_journey", {
      question: (state) => state.question,
      find_car: (state) => state.find_car,
    }),
    ...mapState("test_drive", {
      form_test_drive: (state) => state.form_test_drive,
    }),
    ...mapState("booking_order", {
      form_booking_order_pick_car: (state) => state.form_booking_order_pick_car,
      info_selected: (state) => state.info_selected,
    }),
    ...mapState(["errors"]),
    ...mapState(["isSave"]),
  },
  watch: {
    car_category: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.getCarName({
            category: this.category,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    var vm = this;
    //reloading carousel sliders
    // desktop
    this.getSlider()
      .then((res) => {
        vm.owl_slider_desktop = this.sliders.desktop;
        if (vm.owl_slider_desktop.length > 0) {
          vm.loaded_slider_desktop = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselIntroSlider();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    //mobile
    this.getSlider()
      .then((res) => {
        vm.owl_slider_mobile = this.sliders.mobile;
        if (vm.owl_slider_mobile.length > 0) {
          vm.loaded_slider_mobile = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselIntroSlider();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    // reloading recomended product
    this.getRecomended()
      .then((res) => {
        vm.owl_recomended = this.recomendproduct;
        if (vm.owl_recomended.length > 0) {
          vm.loaded_recomended_product = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselRecomendedProduct();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    // reloading Special Offers
    this.getSpecialOffers()
      .then((res) => {
        vm.owl_special_offer = this.specialoffers;
        if (vm.owl_special_offer.length > 0) {
          vm.loaded_special_offers = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselSpecialOffers();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    // reloading promotion
    this.getAfterSale()
      .then((res) => {
        vm.owl_after_sale = this.after_sale;
        if (vm.owl_after_sale.banner.length > 0) {
          vm.loaded_after_sale = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselAfterSale();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    this.$nextTick(() => {
      // attr data-background
      $("[data-background]").each(function () {
        $(this).css(
          "background-image",
          "url(" + $(this).attr("data-background") + ")"
        );
      });

      // stickyFill
      function stickyFillInit() {
        $(window)
          .on("resize orientationchange", function () {
            recalc();
          })
          .resize();

        function recalc() {
          if ($(".news-sidebar").length > 0) {
            var elements = $(".news-sidebar");
            Stickyfill.add(elements);
          }
        }
      }
      stickyFillInit();

      $(".popup-video").magnificPopup({
        disableOn: 320,
        type: "iframe",
        removalDelay: 150,
        preloader: false,
        fixedContentPos: false,
      });

      // mega menu js
      $(".js-mega-menu").HSMegaMenu({
        event: "hover",
        pageContainer: $(".container"),
        breakpoint: 767.98,
        hideTimeOut: 0,
      });

      // Off Canvas Menu Open & Close
      $("#offCanvas").on("click", function () {
        $(".nav-offcanvas").addClass("open");
        $(".offcanvas-overlay").addClass("on");
      });
      $("#offCanvasClose, .offcanvas-overlay").on("click", function () {
        $(".nav-offcanvas").removeClass("open");
        $(".offcanvas-overlay").removeClass("on");
      });

      // fixed navbar
      $(window).on("scroll", function () {
        if ($(this).scrollTop() > 0) {
          $(".main-header-menu-wrap").addClass("affix");
          $(".scroll-to-target").addClass("open");
        } else {
          $(".main-header-menu-wrap").removeClass("affix");
          $(".scroll-to-target").removeClass("open");
        }

        if ($(this).scrollTop() > 500) {
          $(".scroll-to-target").addClass("open");
        } else {
          $(".scroll-to-target").removeClass("open");
        }
      });

      if ($(".datepicker").length) {
        var date = new Date();
        var today = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        $(".datepicker").datepicker({
          format: "yyyy-mm-dd",
          todayHighlight: false,
          autoclose: true,
        });
        $(".datepicker").datepicker("setDate", today);
      }

      $(".timepicker").datetimepicker({
        use24hours: true,
        format: "HH:mm",
      });

      $(".m-jenis-mobil").change(function () {
        if ($(this).val() == "5") {
          $(".m-jenis-mobil-bisnis").removeClass("d-none");
        } else {
          $(".m-jenis-mobil-bisnis").addClass("d-none", true);
        }
      });
    });
  },
  methods: {
    ...mapActions("home", [
      "getSlider",
      "getIcons",
      "getRecomended",
      "getSpecialOffers",
      "getNews",
      "getValues",
      "getCategoryCar",
      "getCarName",
      "getAfterSale",
    ]),
    ...mapActions("nasmoco_journey", ["getQuestion", "submitResult"]),
    ...mapActions("detail_car", ["getDetailCarByID", "Clear_State"]),
    goToLinkSlider(link) {
      //alert(link);
      // 20210820 - rev_ari
      // window.open("//" + link, "_blank");
      if(link != ''){
        window.open(link, "_blank");
      }
    },
    goToLinkSpecialOffer(link) {
      // alert(link);
      window.open("//" + link, "_blank");
    },
    goToLinkBanner(link) {
      window.open("//" + link, "_blank");
    },
    // Owl Carousel Section
    OwlCarouselIntroSlider: function () {
      if ($(".intro-slider").length) {
        var introSlider = $(".intro-slider");

        introSlider.owlCarousel({
          loop: true,
          autoplay: true,
          speed: 3000,
          smartSpeed: 2000,
          items: 1,
          nav: false,
          dots: false,
          slideBy: 1,
          dotsContainer: ".intro-slider-dots",
          margin: 0,
          autoplayHoverPause: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
        });

        if ($(".intro-slider-next").length) {
          var next = $(".intro-slider-next");
          next.on("click", function () {
            introSlider.trigger("next.owl.carousel");
          });
        }
        if ($(".intro-slider-prev").length) {
          var prev = $(".intro-slider-prev");
          prev.on("click", function () {
            introSlider.trigger("prev.owl.carousel");
          });
        }
      }
    },
    OwlCarouselRecomendedProduct: function () {
      if ($(".product-slider").length) {
        var productSlider = $(".product-slider");

        productSlider.owlCarousel({
          loop: true,
          autoplay: true,
          speed: 3800,
          smartSpeed: 2000,
          nav: false,
          autoWidth: false,
          // dots   	  : false,
          dots: true,
          dotsContainer: ".slider-dots",
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              margin: 20,
              stagePadding: 40,
            },
            576: {
              items: 2,
              margin: 20,
            },
            768: {
              items: 3,
              margin: 20,
            },
            992: {
              items: 3,
              margin: 20,
            },
            1200: {
              items: 4,
              margin: 20,
            },
          },
        });

        if ($(".product-slider-prev").length) {
          var prev = $(".product-slider-prev");
          prev.on("click", function () {
            productSlider.trigger("prev.owl.carousel");
          });
        }

        if ($(".product-slider-next").length) {
          var next = $(".product-slider-next");
          next.on("click", function () {
            productSlider.trigger("next.owl.carousel");
          });
        }
      }
    },
    OwlCarouselSpecialOffers: function () {
      if ($(".spesial-offer-slider").length) {
        var spesialofferSlider = $(".spesial-offer-slider");

        spesialofferSlider.owlCarousel({
          loop: true,
          center: true,
          autoplay: true,
          speed: 3800,
          autoplayTimeout: 8500,
          smartSpeed: 900,
          nav: false,
          autoWidth: false,
          dots: true,
          dotsContainer: ".spesial-offer-slider-dots",
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              margin: 20,
            },
            576: {
              items: 1,
              margin: 20,
            },
            768: {
              items: 3,
              margin: 10,
            },
            992: {
              items: 3,
              margin: 10,
            },
            1200: {
              items: 3,
              margin: 10,
            },
          },
        });
      }
    },
    OwlCarouselAfterSale: function () {
      if ($(".promotion-slider").length) {
        var promotionSlider = $(".promotion-slider");

        promotionSlider.owlCarousel({
          loop: true,
          autoplay: true,
          speed: 3800,
          smartSpeed: 2000,
          autoWidth: false,
          dots: false,
          items: 1,
          margin: 30,
          nav: false,
          navClass: ["promotion-slider-prev", "promotion-slider-next"],
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
        });

        if ($(".promotion-slider-prev").length) {
          var prev = $(".promotion-slider-prev");
          prev.on("click", function () {
            promotionSlider.trigger("prev.owl.carousel");
          });
        }

        if ($(".promotion-slider-next").length) {
          var next = $(".promotion-slider-next");
          next.on("click", function () {
            promotionSlider.trigger("next.owl.carousel");
          });
        }
      }
    },
    // End Owl Carousel Section
    findDreamCar() {
      this.$Progress.start();
      this.submitResult()
        .then((res) => {
          console.log(res);
          this.$router.push({ name: "Nasmoco_journey" });
          this.$Progress.finish();
        })
        .catch((err) => {
          this.$Progress.fail();
        });
    },
    goToService(link) {
      this.$Progress.start();
      this.$router.push({
        name: "After_sale_detail",
        params: {
          link: link,
        },
      });
      this.$Progress.finish();
    },
    setValIDQuestion1(val) {
      this.find_car.question_1 = val.value_id;
      this.find_car.question_1_title = val.question;
    },
    setValIDQuestion2(val) {
      this.find_car.question_2 = val.value_id;
      this.find_car.question_2_title = val.question;
    },
    setValIDQuestion3(val) {
      this.find_car.question_3 = val.value_id;
      this.find_car.question_3_title = val.question;
    },
    getCarList(car_category) {
      this.index_car_name = 0;
      this.getCarName({
        category: car_category,
      })
        .then((response) => {
          console.log("Berhasil Menampilkan Data");
        })
        .catch((error) => {
          console.log("Gagal Menampilkan Data");
        });
    },
    toDetailCar(seo) {
      this.$Progress.start();
      this.Clear_State();
      this.getDetailCarByID(seo)
        .then((res) => {
          this.$router.push({
            name: "Detail_car",
            params: {
              link: seo,
            },
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log("Gagal Mengambil Data");
          this.$Progress.fail();
        });
    },
    toTestDrive(data) {
      this.$Progress.start();
      this.form_test_drive.car_category = data.category_id;
      this.form_test_drive.car_category_name = data.category;
      this.form_test_drive.car_model = data.car_model_id;
      this.form_test_drive.car_model_name = data.name;
      this.$router.push({
        name: "Test_drive",
      });
    },
    toBuyCar(data) {
      this.$Progress.start();
      this.form_booking_order_pick_car.car_category = data.category_id;
      this.form_booking_order_pick_car.car_category_name = data.category;
      this.info_selected.category_car = data.category;
      this.form_booking_order_pick_car.car_model = data.car_model_id;
      this.form_booking_order_pick_car.car_model_name = data.name;
      this.info_selected.car_model_name = data.name;
      this.form_booking_order_pick_car.car_transmission = "";
      this.form_booking_order_pick_car.car_transmission_name = "";
      this.$router.push({
        name: "Order",
      });
    },
    toBuyToss(data) {
      this.$Progress.start();
      this.$Progress.finish();
      window.open("https://toss.nasmoco.co.id", "_blank");
    },
  },
};
</script>

<style>
#mySelect .v-select .dropdown-toggle {
  border: none;
}
</style>