import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    kota_cabang: [],
    cabang: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.kota_cabang = [],
            state.cabang = []
    },
    ASSIGN_DATA_KOTA_CABANG(state, payload) {
        state.kota_cabang = payload
    },
    ASSIGN_DATA_CABANG(state, payload) {
        state.cabang = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getKotaCabang({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`cabang/kota`)
                .then((response) => {
                    commit('ASSIGN_DATA_KOTA_CABANG', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCabang({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`cabang`, {
                city_code: payload.city_code
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CABANG', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
