import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    form_contact_us: {
        name: "",
        email: "",
        phone: "",
        address: "",
        text: ""
    },
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.form_contact_us = {
                name: "",
                email: "",
                phone: "",
                address: "",
                text: ""
            }
    },
    CLEAR_FORM(state) {
        state.form_contact_us = {
            name: "",
            email: "",
            phone: "",
            address: "",
            text: ""
        }
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    insertContactUs({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`contact`, state.form_contact_us)
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    commit('CLEAR_FORM')
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 422) {
                        commit('SET_ERRORS', "Periksa Kembali Semua Form", {
                            root: true
                        })
                    }
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
