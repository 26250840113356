import { flatMap } from "lodash";
import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    // get ip public 
    form_visitor: {
        date: new Date(),
        ip_address: ""
    },
    message_visitor: "",
    //get slider
    sliders: [],
    loading_slider: false,
    icons: [],
    loading_icons: false,
    recomendproduct: [],
    loading_recomendproduct: false,
    specialoffers: [],
    loading_specialoffers: false,
    news: [],
    loading_news: false,
    values: [],
    loading_values: false,
    after_sale: [],
    loading_after_sale: false,
    category: "",
    car_category: [],
    loading_carcategory: false,
    car_name: [],
    loading_car_name: false,

    //cek token message
    message_token: "",
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.sliders = [],
            state.loading_slider = false,
            state.icons = [],
            state.loading_icons = false,
            state.recomendproduct = [],
            state.loading_recomendproduct = false,
            state.specialoffers = [],
            state.loading_specialoffers = false,
            state.news = [],
            state.loading_news = false,
            state.values = [],
            state.loading_values = false,
            state.after_sale = [],
            state.loading_after_sale = false,
            state.category = "",
            state.car_category = [],
            state.loading_carcategory = false,
            state.car_name = [],
            state.loading_car_name = false
    },
    ASSIGN_DATA_SLIDER(state, payload) {
        state.sliders = payload;
    },
    ASSIGN_DATA_ICONS(state, payload) {
        state.icons = payload;
    },
    ASSIGN_DATA_RECOMENDED(state, payload) {
        state.recomendproduct = payload;
    },
    ASSIGN_DATA_SPECIAL_OFFERS(state, payload) {
        state.specialoffers = payload;
    },
    ASSIGN_DATA_NEWS(state, payload) {
        state.news = payload;
    },
    ASSIGN_DATA_VALUES(state, payload) {
        state.values = payload;
    },
    ASSIGN_DATA_CAR_CATEGORY(state, payload) {
        state.car_category = payload;
    },
    ASSIGN_DATA_CAR_NAME(state, payload) {
        state.car_name = payload;
    },
    ASSIGN_DATA_AFTER_SALE(state, payload) {
        state.after_sale = payload;
    },
    CLEAR_CAR_NAME(state) {
        state.car_name = [];
    },
    ASSIGN_MESSAGE_VISITOR(state, payload) {
        state.message_form_visitor = payload
    },
    ASSIGN_MESSAGE_TOKEN(state, payload) {
        state.message_token = payload
    },
    CLEAR_MESSAGE_TOKEN(state) {
        state.message_token = ""
    }
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    cekToken({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`auth`, {
                email: payload.email,
                token: payload.token
            })
                .then((response) => {
                    commit('ASSIGN_MESSAGE_TOKEN', response.data.message)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    Send_Visitor({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`visitor`, state.form_visitor)
                .then((response) => {
                    commit('ASSIGN_MESSAGE_VISITOR', response.data.message)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    // get Item Slider
    getCategoryCar({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_carcategory = true;
            $axios.get(`carcategory`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_CAR_CATEGORY', response.data.data)
                    state.category = response.data.data[0].category_name;
                    state.loading_carcategory = false;
                    resolve(response.data)
                })
        })
    },
    // get Item Icons
    getCarName({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_car_name = true;
            $axios.post(`carname`, {
                category: payload.category,
            })
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('CLEAR_CAR_NAME')
                    commit('ASSIGN_DATA_CAR_NAME', response.data.data)
                    state.loading_car_name = false;
                    resolve(response.data)
                })
        })
    },
    // get Item Slider
    getSlider({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_slider = true;
            $axios.get(`slider`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_SLIDER', response.data.data)
                    state.loading_slider = false;
                    resolve(response.data)
                })
        })
    },
    // get Item Icons
    getIcons({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_icons = true;
            $axios.get(`icon`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_ICONS', response.data.data)
                    state.loading_icons = false;
                    resolve(response.data)
                })
        })
    },
    // get Item recomended product
    getRecomended({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_recomendproduct = true;
            $axios.get(`recomendedproduct`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_RECOMENDED', response.data.data)
                    state.loading_recomendproduct = false;
                    resolve(response.data)
                })
        })
    },
    // get Item Special Offers
    getSpecialOffers({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_specialoffers = true;
            $axios.get(`specialoffer`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_SPECIAL_OFFERS', response.data.data)
                    state.loading_specialoffers = false;
                    resolve(response.data)
                })
        })
    },
    // get News
    getNews({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_news = true;
            $axios.get(`news/highlight`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_NEWS', response.data.data)
                    state.loading_news = false;
                    resolve(response.data)
                })
        })
    },
    // get Values
    getValues({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_values = true;
            $axios.get(`values`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_VALUES', response.data.data)
                    state.loading_values = false;
                    resolve(response.data)
                })
        })
    },
    // get Values
    getAfterSale({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading_after_sale = true;
            $axios.get(`aftersales`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_AFTER_SALE', response.data.data)
                    state.loading_after_sale = false;
                    resolve(response.data)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
