import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //form auth
    form_login: {
        email: "",
        password: ""
    },
    // form reset password 
    form_reset: {
        email: "",
    },

    //get auth
    sharedPreference: {
        is_login: false,
        token: "",
        unique_id: "",
        return_url: "",
        full_name: "",
        member_email: "",
        phone: "",
        gender: "",
        birth: "",
        province_id: "",
        province_name: "",
        city_id: "",
        city_name: "",
        address: "",
        password: ""
    },
    // form register
    form_register: {
        //profile 
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        address: "",
        gender: "",
        birth: "",
        no_rangka: "",
        no_rangka_status: "",
        province: "",
        province_name: "",
        city: "",
        city_name: "",
        cek_list_agree: "",
        cek_list_penawaran: "",
        channel: "register",
    },

    message_rangka: ""
})
const mutations = {
    ASSIGN_MESSAGE_NO_RANGKA(state, payload) {
        state.message_rangka = payload
    },
    CLEAR_FORM_LOGIN(state) {
        state.form_login = {
            email: "",
            password: ""
        }
    },
    CLEAR_FORM_RESET(state) {
        state.form_reset = {
            email: "",
        }
    },
    CLEAR_FORM_REGISTER(state) {
        state.form_register = {
            //profile 
            name: "",
            email: "",
            password: "",
            confirm_password: "",
            phone: "",
            address: "",
            gender: "",
            birth: "",
            no_rangka: "",
            no_rangka_status: "",
            province: "",
            province_name: "",
            city: "",
            city_name: "",
            cek_list_agree: "",
            cek_list_penawaran: "",
            channel: "register",
        }
    },
}
const actions = {
    submit({
        state,
        commit
    }, payload) {
        localStorage.setItem('token', null)
        commit('SET_TOKEN', null, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post('login', {
                email: state.form_login.email,
                password: state.form_login.password
            })
                .then((response) => {
                    commit('CLEAR_FORM_LOGIN')
                    localStorage.setItem('token', response.data.data.token)
                    localStorage.setItem('sharedPreference', JSON.stringify(response.data.data));
                    commit('SET_TOKEN', response.data.data.token, {
                        root: true
                    })
                    commit('SET_SHARED_PREFERENCE', response.data.data, {
                        root: true
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERRORS', "Email Atau Password Salah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    ResetPassword({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('forgotpassword', state.form_reset)
                .then((response) => {
                    commit('CLEAR_FORM_RESET')
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERRORS', "Email Belum terdaftar", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    submitRegister({
        state,
        commit
    }, payload) {
        localStorage.setItem('token', null)
        commit('SET_TOKEN', null, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post('register', state.form_register)
                .then((response) => {
                    commit('CLEAR_FORM_REGISTER')
                    //remove localstorage
                    localStorage.removeItem('token');
                    localStorage.removeItem('sharedPreference');
                    //set localstorage
                    localStorage.setItem('token', response.data.data.login.token)
                    localStorage.setItem('sharedPreference', JSON.stringify(response.data.data.login));
                    commit('SET_TOKEN', response.data.data.login.token, {
                        root: true
                    })
                    commit('SET_SHARED_PREFERENCE', response.data.data.login, {
                        root: true
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error.response.data.code == 400) {
                        commit('SET_ERRORS', error.response.data.message.alert, {
                            root: true
                        })
                    }
                    reject(error)
                })
        })
    },
    cekNoRangka({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('cekrangka', {
                no_rangka: payload.no_rangka
            })
                .then((response) => {
                    commit('ASSIGN_MESSAGE_NO_RANGKA', response.data.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    commit('SET_ERRORS', error.response.data.message, {
                        root: true
                    })
                    reject(error)
                })
        })
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}