import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    nasmoco_club_id: [],
    nasmoco_club: [],
    page: 1,
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.nasmoco_club_id = [],
            state.nasmoco_club = []
        state.page = 1
    },
    ASSIGN_DATA_NASMOCO_CLUB_ID(state, payload) {
        state.nasmoco_club_id = payload
    },
    ASSIGN_DATA_NASMOCO_CLUB(state, payload) {
        state.nasmoco_club = payload
    },
    SET_PAGE(state, payload) {
        state.page = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getNacmocoClub({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`nasmococlub`)
                .then((response) => {
                    commit('ASSIGN_DATA_NASMOCO_CLUB', response.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getNasmocoClubByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`nasmococlub/detail/${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_NASMOCO_CLUB_ID', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
