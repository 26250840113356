<template>
  <div>
    <div class="copyright">
      <div class="container">
        <center>
          <center>
            <span
              ><a href="#">Nasmoco Group</a> - {{ year }} &copy; All Rights
              Reserved</span
            >
          </center>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  data() {
    return {
      year: "",
    };
  },
  created() {
    this.year = moment().format("YYYY");
    window.scrollTo(0, 0);
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>