import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get slider
    promo: "",
    loading_news_id: false,
    news_read_by_id: [],
    loading_news_populer: false,
    news_read_populer: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.promo = [],
            state.loading_news_id = false,
            state.news_read_by_id = [],
            state.loading_news_populer = false,
            state.news_read_populer = []
    },
    ASSIGN_DATA_PROMO(state, payload) {
        state.promo = payload;
    },
    ASSIGN_DATA_NEWS_BY_ID(state, payload) {
        state.news_read_by_id = payload
    },
    ASSIGN_DATA_NEWS_POPULER_LIST(state, payload) {
        state.news_read_populer = payload
    },
};
const actions = {
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getNewsPromo({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading = true;
            $axios.get(`news/home`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_PROMO', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                })
        })
    },
    getNewsByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_news_id = true;
            $axios.get(`newsdetail/${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_NEWS_BY_ID', response.data.data.news_detail)
                    commit('ASSIGN_DATA_NEWS_POPULER_LIST', response.data.data.news_popular)
                    state.loading_news_id = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
