import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    car_category: [],
    car_model: [],
    loading_car_type: false,
    car_type: [],
    car_color: [],

    info_selected: {
        car_pick_name: "",
        image_pick_color: "",
        category_car: "",
        model_car: "",
        Transmisi: "",
        Tipe_mobil: "",
        Pilihan_Warna: "",
        Cabang: "",
        Promo_Code: "",
        Harga: 0
    },

    form_booking_order_profile: {
        //profile 
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        address: "",
        gender: "",
        birth: "",
        no_rangka: "",
        province: "",
        province_name: "",
        city: "",
        city_name: "",
        cek_list_agree: "",
        cek_list_penawaran: "",
        token: "",
    },

    form_booking_order_pick_car: {
        //pick car
        car_category: "",
        car_category_name: "",
        car_model: "",
        car_model_name: "",
        car_transmission: "AT",
        car_transmission_name: "AT",
        car_type: "",
        kota_cabang: "",
        kota_cabang_name: "",
        cabang_id: "",
        cabang_id_name: "",
    },
    form_booking_order_pick_color: {
        // pick color
        car_color: "",
    },
    form_booking_order_payment_cash: {
        payment_type: "cash",  // default cash
        price_otr: 0,
        price_otr_promo: 0,
        promotion_code: "",
        channel: "booking-order"
    },
    form_booking_order_payment_credit: {
        uang_muka: 0,
        uang_min: 0,
        uang_max: 0,
        step_uang_muka: 0,
        tenor: 12,
        ktp: "",
        angsuran: 0,
        angsuran_promo: 0,
    },

    result_booking_order: [],
    bookingfee: 0,

    result_promotion_code: [],
    message_promo: "",
    result_promotion_credit: [],
    message_promo_credit: "",


});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.car_category = [],
            state.car_model = [],
            state.loading_car_type = false,
            state.car_type = [],
            state.car_color = [],

            state.info_selected = {
                car_pick_name: "",
                image_pick_color: "",
                category_car: "",
                model_car: "",
                Transmisi: "",
                Tipe_mobil: "",
                Pilihan_Warna: "",
                Cabang: "",
                Promo_Code: "",
                Harga: 0
            },
            state.form_booking_order_profile = {
                //profile 
                name: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                birth: "",
                no_rangka: "",
                province: "",
                province_name: "",
                city: "",
                city_name: "",
                cek_list_agree: "",
                cek_list_penawaran: "",
                token: "",
            },

            state.form_booking_order_pick_car = {
                //pick car
                car_category: "",
                car_category_name: "",
                car_model: "",
                car_model_name: "",
                car_transmission: "AT",
                car_transmission_name: "AT",
                car_type: "",
                kota_cabang: "",
                kota_cabang_name: "",
                cabang_id: "",
                cabang_id_name: "",
            },
            state.form_booking_order_pick_color = {
                // pick color
                car_color: "",
            },
            state.form_booking_order_payment_cash = {
                payment_type: "cash",  // default cash
                price_otr: 0,
                price_otr_promo: 0,
                promotion_code: "",
                channel: "booking-order"
            },
            state.form_booking_order_payment_credit = {
                uang_muka: 0,
                uang_min: 0,
                uang_max: 0,
                step_uang_muka: 0,
                tenor: 12,
                ktp: "",
                angsuran: 0,
                angsuran_promo: 0,
            },
            state.result_promotion_code = [],
            state.message_promo = "",
            state.result_promotion_credit = [],
            state.message_promo_credit = ""
    },

    CLEAR_FORM(state) {
        state.info_selected = {
            car_pick_name: "",
            image_pick_color: "",

            category_car: "",
            model_car: "",
            Transmisi: "",
            Tipe_mobil: "",
            Pilihan_Warna: "",
            Cabang: "",
            Promo_Code: "",
            Harga: 0
        },
            state.form_booking_order_profile = {
                //profile 
                name: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                birth: "",
                no_rangka: "",
                province: "",
                province_name: "",
                city: "",
                city_name: "",
                cek_list_agree: "",
                cek_list_penawaran: ""
            },

            state.form_booking_order_pick_car = {
                //pick car
                car_category: "",
                car_category_name: "",
                car_model: "",
                car_model_name: "",
                car_transmission: "AT",
                car_transmission_name: "AT",
                car_type: "",
                kota_cabang: "",
                kota_cabang_name: "",
                cabang_id: "",
                cabang_id_name: "",
            },
            state.form_booking_order_pick_color = {
                // pick color
                car_color: "",
            },
            state.form_booking_order_payment_cash = {
                payment_type: "cash",  // default cash
                price_otr: 0,
                price_otr_promo: 0,
                promotion_code: "",
                channel: "booking-order"
            },
            state.form_booking_order_payment_credit = {
                uang_muka: 0,
                uang_min: 0,
                uang_max: 0,
                step_uang_muka: 0,
                tenor: 12,
                ktp: "",
                angsuran: 0,
                angsuran_promo: 0,
            },
            state.result_promotion_code = [],
            state.message_promo = "",
            state.result_promotion_credit = [],
            state.message_promo_credit = ""
    },
    ASSIGN_DATA_RESULT_ORDER(state, payload) {
        state.result_booking_order = payload
    },
    ASSIGN_BOOKING_FEE(state, payload) {
        state.bookingfee = payload
    },
    // for combo box
    ASSIGN_DATA_CAR_CATEGORY(state, payload) {
        state.car_category = payload
    },
    ASSIGN_DATA_CAR_MODEL(state, payload) {
        state.car_model = payload
    },
    ASSIGN_DATA_CAR_TYPE(state, payload) {
        state.car_type = payload
    },
    ClEAR_DATA_CAR_TYPE(state) {
        state.car_type = []
    },
    ASSIGN_DATA_CAR_COLOR(state, payload) {
        state.car_color = payload
        state.form_booking_order_pick_color.car_color = payload[0].color_id
        state.info_selected.image_pick_color = payload[0].image
        state.info_selected.Pilihan_Warna = payload[0].color_name
    },
    ASSIGN_DATA_PROMOTION_CODE(state, payload) {
        state.result_promotion_code = payload
    },
    CLEAR_DATA_PROMOTION_CODE(state) {
        state.result_promotion_code = []
    },
    ASSIGN_DATA_PROMOTION_CODE_CREDIT(state, payload) {
        state.result_promotion_credit = payload
    },
    CLEAR_DATA_PROMOTION_CODE_CREDIT(state) {
        state.result_promotion_credit = []
    },
    CLEAR_MESSAGE_PROMO(state) {
        state.message_promo = ""
    },
    CLEAR_MESSAGE_PROMO_CREDIT(state) {
        state.message_promo_credit = ""
    }
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    Clear_Result_Promotion_Credit({ commit }) {
        return new Promise(() => {
            commit("CLEAR_DATA_PROMOTION_CODE_CREDIT");
        });
    },
    Clear_Result_Promotion_Cash({ commit }) {
        return new Promise(() => {
            commit("CLEAR_DATA_PROMOTION_CODE");
        });
    },
    Clear_Message_Promo_Cash({ commit }) {
        return new Promise(() => {
            commit("CLEAR_MESSAGE_PROMO");
        });
    },
    Clear_Message_Promo_Credit({ commit }) {
        return new Promise(() => {
            commit("CLEAR_MESSAGE_PROMO_CREDIT");
        });
    },
    Clear_Data_Car_Type({ commit }) {
        return new Promise(() => {
            commit("ClEAR_DATA_CAR_TYPE");
        });
    },
    getCarCategory({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`carcategory`)
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_CATEGORY', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarModel({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`carmodel`, {
                category_id: payload.category_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_MODEL', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarTypeOrder({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_type = true;
            $axios.post(`order/type`, {
                car_model_id: payload.car_model_id,
                car_transmission: payload.car_transmission
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_TYPE', response.data.data)
                    state.loading_car_type = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarColor({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`order/warna`, {
                car_model_id: payload.car_model_id,
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_COLOR', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    insertDataBookingOrder({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`order`, {
                "name": payload.name,
                "email": payload.email,
                "phone": payload.phone,
                "address": payload.address,
                "gender": payload.gender,
                "birth": payload.birth,
                "no_rangka": payload.no_rangka,
                "password": payload.password,
                "province": payload.province,
                "city": payload.city,
                "car_category": payload.car_category,
                "car_model": payload.car_model,
                "car_transmission": payload.car_transmission,
                "car_type": payload.car_type,
                "cabang_id": payload.cabang_id,
                "car_color": payload.car_color,
                "payment_type": payload.payment_type,
                "price_otr": payload.price_otr,
                "price_otr_promo": payload.price_otr_promo,
                "uang_muka": payload.uang_muka,
                "tenor": payload.tenor,
                "angsuran": payload.angsuran,
                "angsuran_promo": payload.angsuran_promo,
                "promotion_code": payload.promotion_code,
                "ktp": payload.ktp,
                "token": payload.token,
                "channel": "booking-order",
            })
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (state.form_booking_order_profile.token != "") {
                        commit('CLEAR_FORM')
                        commit('ASSIGN_DATA_RESULT_ORDER', response.data.data.login)
                        commit('ASSIGN_BOOKING_FEE', response.data.data.login.bookingfee)
                    }
                    else if (state.form_booking_order_profile.token == "") {
                        commit('CLEAR_FORM')
                        commit('ASSIGN_DATA_RESULT_ORDER', response.data.data.login)
                        commit('ASSIGN_BOOKING_FEE', response.data.data.login.bookingfee)
                        //remove localstorage
                        localStorage.removeItem('token');
                        localStorage.removeItem('sharedPreference');
                        //set localstorage
                        localStorage.setItem('token', response.data.data.login.token)
                        localStorage.setItem('sharedPreference', JSON.stringify(response.data.data.login));
                        commit('SET_TOKEN', response.data.data.login.token, {
                            root: true
                        })
                        commit('SET_SHARED_PREFERENCE', response.data.data.login, {
                            root: true
                        })
                    }

                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 400) {
                        commit('SET_ERRORS', error.response.data.message, {
                            root: true
                        })
                    }
                    reject(error)
                })
        })
    },
    getPromotionCode({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`voucher`, {
                promo_code: payload.promo_code,
                car_type: payload.car_type,
                payment_type: payload.payment_type,
                down_payment: payload.down_payment,
                tenor: payload.tenor
            })
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    commit('CLEAR_DATA_PROMOTION_CODE')
                    state.message_promo = response.data.message
                    commit('ASSIGN_DATA_PROMOTION_CODE', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 400) {
                        commit('CLEAR_DATA_PROMOTION_CODE')
                        state.message_promo = error.response.data.message
                    }
                    reject(error)
                })
        })
    },
    getPromotionCodeCredit({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`voucher`, {
                promo_code: payload.promo_code,
                car_type: payload.car_type,
                payment_type: payload.payment_type,
                down_payment: payload.down_payment,
                tenor: payload.tenor
            })
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    commit('CLEAR_DATA_PROMOTION_CODE_CREDIT')
                    state.message_promo_credit = response.data.message
                    commit('ASSIGN_DATA_PROMOTION_CODE_CREDIT', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.data.code == 400) {
                        commit('CLEAR_DATA_PROMOTION_CODE_CREDIT')
                        state.message_promo_credit = error.response.data.message
                    }
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
