<template>
  <div>
    <section class="intro-detail-product"> 
             <!-- <p class="section-subheading">TEST DRIVE</p>
              <h2 class="section-heading">
                Selangkah Lebih Dekat dengan Mobil Impian Anda
              </h2>
              -->
              <!-- desktop --> 
          <img
           data-src="assets/img/poster-veloz2.jpg"
            class="img-fluid lazyload img-product d-none d-md-block"
          />
          <!-- mobile --> 
          <img
           data-src="assets/img/web 750x1330px.jpg"
            class="img-fluid lazyload img-product d-block d-md-none"
          />    
          
    </section>
    <section class="top-heading-product">
          <!-- price car model  -->
          <div class="heading-product mt-4">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 align-self-center mb-lg-0 mb-3">
                  <h2 
                    class="section-heading-xs mb-0"
                  > 
                  </h2>
                  <h2 
                    class="section-heading-xs mb-0"
                  >
                     <a href="https://form.jotform.com/213181574519458" target="_blank"
                    class="btn btn-lg btn-info px-3 rounded-10"
                  >
                  <!-- btn-accent (merah) -->
                    TEST DRIVE
                    <span
                      class="icon trans-4 iconify"
                      data-inline="false"
                      data-icon="tabler:steering-wheel"
                    ></span>
                  </a>
                  </h2>
                </div>

                <div class="col-lg-4 px-lg-0 mb-lg-0 mb-3">
                  <div class="price-display-2">
                    
                  </div>
                </div>
                 
                <div
                  class="col-lg-4 align-self-center text-sm-center text-lg-center wrap-btn-mn"
                >
                  
                </div>
              </div>
            </div>
          </div>
    </section> 
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "Testdriveallnewveloz",
  metaInfo() {
    return {
      title: "Driving Experience All New Veloz - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {
      SortBy: [
        {
          label: "Terbaru",
          value: "Terbaru",
        },
        {
          label: "Terendah",
          value: "Terendah",
        },
        {
          label: "Harga Terendah",
          value: "Harga Terendah",
        },
        {
          label: "Harga Tertingi",
          value: "Harga Tertingi",
        },
      ],
      Category: [
        {
          label: "Sparepart",
          value: "sparepart",
        },
        {
          label: "Accesories",
          value: "accesories",
        },
      ],

      search: "",
      category: "",
      sortby: "Terbaru",
    };
  },
  created() {
    this.getSparepart({
      search: this.search,
      filter: this.category,
    });
    window.scrollTo(0, 0);
  },
  watch: {
    search() {
      this.getSparepart({
        search: this.search,
        filter: this.category,
      });
    },
    category() {
      this.getSparepart({
        search: this.search,
        filter: this.category,
      });
    },
  },
  computed: {
    ...mapState("Testdriveallnewveloz", {
      sparepart: (state) => state.sparepart,
      total: (state) => state.total,
    }),
  },
  mounted() {
    $(document).ready(function () {
      // attr data-background
      $("[data-background]").each(function () {
        $(this).css(
          "background-image",
          "url(" + $(this).attr("data-background") + ")"
        );
      });
    });
  },
  methods: {
    ...mapActions("Testdriveallnewveloz", ["getSparepart"]),
    setValFilter(val) {
      this.sortby = val.value;
    },
    toTestDriveVeloz() {
      this.$Progress.start();
      this.$Progress.finish();
      window.open("https://form.jotform.com/213181574519458", "_blank");
    },
    setValCategory(val) {
      this.category = val.value;
    },
  },
};
</script>