import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get slider
    after_sale_by_id: [],
    after_sale_detail: [],

    //testimoni 
    testimoni: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.after_sale_by_id = [],
            state.after_sale_detail = []
    },
    ASSIGN_DATA_AFTER_SALE_BY_ID(state, payload) {
        state.after_sale_by_id = payload
    },
    ASSIGN_DATA_AFTER_SALE_DETAIL(state, payload) {
        state.after_sale_detail = payload
    },
    ASSIGN_DATA_AFTER_SALE_TESTIMONI(state, payload) {
        state.testimoni = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getAfterSaleByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`service/${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_AFTER_SALE_BY_ID', response.data.data.header)
                    commit('ASSIGN_DATA_AFTER_SALE_DETAIL', response.data.data.content)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getAfterSaleTestimoni({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`testimoni`)
                .then((response) => {
                    commit('ASSIGN_DATA_AFTER_SALE_TESTIMONI', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
