import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo

    car_category: [],
    car_model: [],
    car_type: [],

    // detail car
    loading_car_id: "",
    meta_title: "",
    car_model_id: "",
    car_model_name: "",
    car_model_seo: "",

    // car default 
    loading_car_default: "",
    car_default: [],

    car_sliders: [],
    car_sliders_mobile: [],
    car_featured: [],
    loading_car_youtube: false,
    car_youtube: [],
    car_color: [],
    car_exterior: [],
    loading_car_exterior: false,
    car_exterior_360: [],
    car_interior: [],
    loading_car_interior: false,
    car_interior_360: [],
    loading_car_type: false,
    car_detail_type: [],
    cta: "",
    // end detail car

    // credit simulation
    form_credit_simulation: {
        car_category_id: "",
        car_category_name: "",
        car_model_id: "",
        car_model_name: "",
        car_transmision: "AT",
        car_model_type: "",
        car_model_type_name: "",
        car_type_price_otr: 0,
        car_type_price_credit: 0,
        uang_min: 0,
        uang_max: 0,
        step_uang_muka: 0,
        tenor: 60,
        uang_muka: 0
    },

    // result credit
    loading_result_credit: false,
    result_credit: []
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            //get car by seo

            state.car_category = [],
            state.car_model = [],
            state.car_type = [],

            // detail car
            state.loading_car_id = "",
            state.meta_title = "",
            state.car_model_id = "",
            state.car_model_name = "",
            state.car_model_seo = "",

            // car default 
            state.loading_car_default = "",
            state.car_default = [],
            state.car_sliders = [],
            state.car_sliders_mobile = [],
            state.car_featured = [],
            state.loading_car_youtube = false,
            state.car_youtube = [],
            state.car_color = [],
            state.car_exterior = [],
            state.loading_car_exterior = false,
            state.car_exterior_360 = [],
            state.car_interior = [],
            state.loading_car_interior = false,
            state.car_interior_360 = [],
            state.loading_car_type = false,
            state.car_detail_type = [],
            state.cta = "",
            // end detail car

            // credit simulation
            state.form_credit_simulation = {
                car_category_id: "",
                car_category_name: "",
                car_model_id: "",
                car_model_name: "",
                car_transmision: "AT",
                car_model_type: "",
                car_model_type_name: "",
                car_type_price_otr: 0,
                car_type_price_credit: 0,
                uang_min: 0,
                uang_max: 0,
                step_uang_muka: 0,
                tenor: 60,
                uang_muka: 0
            },

            // result credit
            state.loading_result_credit = false,
            state.result_credit = []
    },
    CLEAR_CAR_PRICE(state) {
        state.car_price = []
    },
    CLEAR_FORM_CREDIT_SIMULATION(state) {
        state.form_credit_simulation = {
            car_category_id: "",
            car_category_name: "",
            car_model_id: "",
            car_model_name: "",
            car_transmision: "AT",
            car_model_type: "",
            car_model_type_name: "",
            car_type_price_otr: 0,
            car_type_price_credit: 0,
            uang_min: 0,
            uang_max: 0,
            step_uang_muka: 0,
            tenor: 60,
            uang_muka: 0
        },
            state.result_credit = []
    },
    ASSIGN_DATA_CAR_MODEL_SEO(state, payload) {
        state.car_model_seo = payload
    },
    ASSIGN_DATA_CAR_MODEL_ID(state, payload) {
        state.car_model_id = payload
    },
    ASSIGN_DATA_CAR_META_TITLE(state, payload) {
        state.meta_title = payload
    },
    ASSIGN_DATA_CAR_MODEL_NAME(state, payload) {
        state.car_model_name = payload
    },
    ASSIGN_DATA_CAR_SIMULATION(state, payload) {
        state.result_credit = payload
    },
    CLEAR_DATA_CAR_SIMULATION(state) {
        state.result_credit = []
    },
    ASSIGN_DATA_CAR_SLIDERS(state, payload) {
        state.car_sliders = payload
    },
    ASSIGN_DATA_CAR_SLIDERS_MOBILE(state, payload) {
        state.car_sliders_mobile = payload
    },
    ASSIGN_DATA_CAR_DEFAULT(state, payload) {
        state.car_default = payload
    },
    ASSIGN_DATA_CAR_FEATURED(state, payload) {
        state.car_featured = payload
    },
    ASSIGN_DATA_CAR_YOUTUBE(state, payload) {
        state.car_youtube = payload
    },
    ASSIGN_DATA_CAR_COLOR(state, payload) {
        state.car_color = payload
    },
    ASSIGN_DATA_CAR_EXTERIOR(state, payload) {
        state.car_exterior = payload
    },
    ASSIGN_DATA_CAR_EXTERIOR_360(state, payload) {
        state.car_exterior_360 = payload
    },
    ASSIGN_DATA_CAR_INTERIOR(state, payload) {
        state.car_interior = payload
    },
    ASSIGN_DATA_CAR_INTERIOR_360(state, payload) {
        state.car_interior_360 = payload
    },
    ASSIGN_DATA_CAR_DETAIL_TYPE(state, payload) {
        state.car_detail_type = payload
    },
    ASSIGN_DATA_CAR_DETAIL_CTA(state, payload) {
        state.cta = payload
    },
    // for combo box
    ASSIGN_DATA_CAR_CATEGORY(state, payload) {
        state.car_category = payload
    },
    ASSIGN_DATA_CAR_MODEL(state, payload) {
        state.car_model = payload
    },
    ASSIGN_DATA_CAR_TYPE(state, payload) {
        state.car_type = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    Clear_State_Credit_Simulation({ commit }) {
        return new Promise(() => {
            commit("CLEAR_DATA_CAR_SIMULATION");
        });
    },
    getDetailCarByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_id = true
            $axios.post(`cardetail`, {
                car_seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_META_TITLE', response.data.data.meta_title)
                    commit('ASSIGN_DATA_CAR_MODEL_ID', response.data.data.car_model_id)
                    commit('ASSIGN_DATA_CAR_MODEL_SEO', response.data.data.car_model_seo)
                    commit('ASSIGN_DATA_CAR_MODEL_NAME', response.data.data.car_model_name)

                    state.form_credit_simulation.car_model_id = response.data.data.car_model_id
                    state.form_credit_simulation.car_model_name = response.data.data.car_model_name

                    commit('ASSIGN_DATA_CAR_SLIDERS', response.data.data.slider)
                    commit('ASSIGN_DATA_CAR_SLIDERS_MOBILE', response.data.data.slider_mobile)
                    commit('ASSIGN_DATA_CAR_FEATURED', response.data.data.featured)
                    commit('ASSIGN_DATA_CAR_COLOR', response.data.data.color)

                    commit('ASSIGN_DATA_CAR_EXTERIOR', response.data.data.exterior)
                    commit('ASSIGN_DATA_CAR_INTERIOR', response.data.data.interior)
                    commit('ASSIGN_DATA_CAR_DETAIL_CTA', response.data.data.cta)
                    state.loading_car_id = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getSimulationCar({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            commit('CLEAR_CAR_PRICE')
            state.loading_result_credit = true
            $axios.post(`creditsimulation`, {
                car_model_id: payload.car_model_id,
                car_model_transmission: payload.car_model_transmission,
                car_model_type: payload.car_model_type,
                uang_muka: payload.uang_muka,
                tenor: payload.tenor,
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_SIMULATION', response.data.data)
                    state.form_credit_simulation.car_model_type_name = response.data.data.car_type_name
                    state.form_credit_simulation.car_type_price_otr = response.data.data.price_otr
                    state.form_credit_simulation.car_type_price_credit = response.data.data.angsuran
                    state.form_credit_simulation.tenor = response.data.data.tenor
                    state.loading_result_credit = false,
                        resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarCategory({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`carcategory`)
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_CATEGORY', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarModel({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`carmodel`, {
                category_id: payload.category_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_MODEL', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarType({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`cartype`, {
                car_model_id: payload.car_model_id,
                car_transmission: payload.car_transmission
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_TYPE', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarYoutube({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_youtube = true;
            $axios.post(`caryoutube`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_YOUTUBE', response.data.data)
                    state.loading_car_youtube = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarInterior360({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_interior = true;
            $axios.post(`interior360`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_INTERIOR_360', response.data.data)
                    state.loading_car_interior = false,
                        resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarExterior360({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_exterior = true;
            $axios.post(`exterior360`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_EXTERIOR_360', response.data.data)
                    state.loading_car_exterior = false,
                        resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarDefault({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_default = true;
            $axios.post(`cardefault`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_DEFAULT', response.data.data)
                    state.form_credit_simulation.car_category_id = response.data.data.car_category_id
                    state.form_credit_simulation.car_category_name = response.data.data.car_category_name
                    state.form_credit_simulation.car_model_id = response.data.data.car_model_id
                    state.form_credit_simulation.car_model_name = response.data.data.car_model_name
                    state.form_credit_simulation.car_model_type = response.data.data.car_type_id
                    state.form_credit_simulation.car_model_type_name = response.data.data.car_type_name
                    state.form_credit_simulation.car_transmision = response.data.data.car_transmission
                    state.form_credit_simulation.car_type_price_otr = response.data.data.car_type_price_otr
                    state.form_credit_simulation.car_type_price_credit = response.data.data.car_type_price_credit
                    state.form_credit_simulation.uang_min = response.data.data.uang_min
                    state.form_credit_simulation.uang_max = response.data.data.uang_max
                    state.form_credit_simulation.step_uang_muka = response.data.data.car_type_price_otr * 0.1
                    state.form_credit_simulation.uang_muka = response.data.data.uang_min
                    state.loading_car_default = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getDetailCarByIDType({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_car_type = true;
            $axios.post(`cartypedetail`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_DETAIL_TYPE', response.data.data)
                    state.loading_car_type = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },


};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
