<template>
  <div>
    <section class="compare-car compare-lg section-lg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <div class="box-section-title">
              <h2 class="section-heading-sm">
                Compare <span>Your Dream Car</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="box-compare">
          <div class="flex-2-column">
            <div class="flex-col">
              <div class="box-container">
                <form
                  method="post"
                  action="javascript:void(0)"
                  class="form-style"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label opacity-7"
                          >Kategori Mobil</label
                        >
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_category_1"
                          label="category_name"
                          placeholder="Pilih Kategori Mobil"
                          @input="setValIdkategoriMobil1"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Kategori Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.category_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label opacity-7"
                          >Tipe Mobil</label
                        >
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_model_1"
                          label="car_mode_name"
                          placeholder="Pilih Model Mobil"
                          @input="setValIdCarModel1"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Model Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.car_mode_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label opacity-7"
                          >Model / Variant</label
                        >
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="Car_Transmision"
                          label="label"
                          placeholder="Pilih Transmisi Mobil"
                          @input="setValIdTransmisi1"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Transmisi tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.label
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Tipe Mobil</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_type_1"
                          label="car_type_name"
                          placeholder="Pilih Tipe Mobil"
                          @input="setValIdCarType1"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Tipe Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.car_type_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </form>

                <div
                  v-if="result_car_1.image != ''"
                  class="img-product-container"
                >
                  <img
                    :data-src="result_car_1.image"
                    class="img-fluid lazyload img-product"
                  />
                </div>

                <div
                  v-if="result_car_1.image != ''"
                  class="py-2 w-100 bg-accent section-description-lg font-weight-bold text-center text-white rounded-0"
                >
                  {{ result_car_1.price | currency }}
                </div>
              </div>
            </div>

            <div class="flex-col">
              <div class="box-container">
                <form
                  method="post"
                  action="javascript:void(0)"
                  class="form-style"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label opacity-7"
                          >Kategori Mobil</label
                        >
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_category_2"
                          label="category_name"
                          placeholder="Pilih Kategori Mobil"
                          @input="setValIdkategoriMobil2"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Kategori Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.category_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label opacity-7"
                          >Tipe Mobil</label
                        >
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_model_2"
                          label="car_mode_name"
                          placeholder="Pilih Model Mobil"
                          @input="setValIdCarModel2"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Model Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.car_mode_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label opacity-7"
                          >Model / Variant</label
                        >
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="Car_Transmision"
                          label="label"
                          placeholder="Pilih Transmisi Mobil"
                          @input="setValIdTransmisi2"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Transmisi tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.label
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Tipe Mobil</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_type_2"
                          label="car_type_name"
                          placeholder="Pilih Tipe Mobil"
                          @input="setValIdCarType2"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Tipe Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.car_type_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </form>

                <div
                  class="img-product-container"
                  v-if="result_car_2.image != ''"
                >
                  <img
                    :data-src="result_car_2.image"
                    class="img-fluid lazyload img-product"
                  />
                </div>

                <div
                  v-if="result_car_2.price != ''"
                  class="py-2 w-100 bg-accent section-description-lg font-weight-bold text-center text-white rounded-0"
                >
                  {{ result_car_2.price | currency }}
                </div>
              </div>
            </div>
            <!-- button compare  -->
            <div class="flex-col">
              <div class="text-lg-center text-center">
                <button
                  :disabled="$v.compare_car.$invalid"
                  v-if="!isSave"
                  type="submit"
                  class="btn btn-padd text-center btn-yellow mt-1"
                  @click.prevent="submit"
                >
                  COMPARE NOW
                </button>
                <button
                  v-if="isSave"
                  :disabled="isSave"
                  type="submit"
                  class="btn btn-padd text-center btn-yellow mt-1"
                >
                  <span class="spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </div>
          </div>

          <div class="specification-product compare-lg mt-md-0 mt-4">
            <div id="accordion" class="accordion wrap-specification-collapse">
              <!-- dimensi  -->
              <div class="card" v-if="result_dimensi.length > 0">
                <a
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#spesifikasi0"
                  aria-expanded="false"
                >
                  <h6 class="section-description-sm mb-0 d-inline-block">
                    DIMENSI
                  </h6>
                </a>
                <div
                  id="spesifikasi0"
                  class="collapse"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body p-0">
                    <!-- <ul class="flex-2-column">
                                            <li class="flex-col">
                                                <label>Number of seat</label>
                                                <p class="value">5</p>
                                            </li>
                                            <li class="flex-col">
                                                <label>Number of seat</label>
                                                <p class="value">5</p>
                                            </li>
                                            <li class="flex-col">
                                                <label>Combined MPG</label>
                                                <p class="value">57.2 MPG</p>
                                            </li>
                                            <li class="flex-col">
                                                <label>Combined MPG</label>
                                                <p class="value">57.2 MPG</p>
                                            </li>
                                            <li class="flex-col">
                                                <label>CO2</label>
                                                <p class="value">129</p>
                                            </li>
                                            <li class="flex-col">
                                                <label>CO2</label>
                                                <p class="value">129</p>
                                            </li>
                                        </ul> -->
                    <table class="table table-striped table-specification">
                      <tr>
                        <td class="p-0 border-none hemm">
                          <tr
                            v-for="(row, index) in result_dimensi[0]"
                            :key="index"
                          >
                            <td>
                              <label>{{ row.title }}</label>
                              <p class="value">{{ row.value }}</p>
                            </td>
                          </tr>
                        </td>

                        <td class="p-0 border-none hemm">
                          <tr
                            v-for="(row, index) in result_dimensi[1]"
                            :key="index"
                            class="border-none"
                          >
                            <td>
                              <label>{{ row.title }}</label>
                              <p class="value">{{ row.value }}</p>
                            </td>
                          </tr>
                        </td>
                      </tr>
                      <!--                       
                      <tr>
                        <td>
                          <label>Jarak Sumbu Roda</label>
                          <p class="value">2655 mm</p>
                        </td>
                        <td>
                          <label>Jarak Sumbu Roda</label>
                          <p class="value">2655 mm</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Jarak Pijak Roda Depan</label>
                          <p class="value">1,425 mm</p>
                        </td>
                        <td>
                          <label>Jarak Pijak Roda Depan</label>
                          <p class="value">1,425 mm</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Jarak Pijak Roda Belakang</label>
                          <p class="value">1435 mm</p>
                        </td>
                        <td>
                          <label>Jarak Pijak Roda Belakang</label>
                          <p class="value">1435 mm</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>Tinggi dari Tanah</label>
                          <p class="value">200 mm</p>
                        </td>
                        <td>
                          <label>Tinggi dari Tanah</label>
                          <p class="value">200 mm</p>
                        </td>
                      </tr> -->
                    </table>
                  </div>
                </div>
              </div>
              <!-- spesifikasi  mesin -->
              <div class="card" v-if="result_mesin.length > 0">
                <a
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#spesifikasi1"
                  aria-expanded="false"
                >
                  <h6 class="section-description-sm mb-0 d-inline-block">
                    SPESIFIKASI MESIN
                  </h6>
                </a>
                <div
                  id="spesifikasi1"
                  class="collapse"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body p-0">
                    <table class="table table-striped table-specification">
                      <tr>
                        <td class="p-0 border-none hemm">
                          <tr
                            v-for="(row, index) in result_mesin[0]"
                            :key="index"
                          >
                            <td>
                              <label>{{ row.title }}</label>
                              <p class="value">{{ row.value }}</p>
                            </td>
                          </tr>
                        </td>

                        <td class="p-0 border-none hemm">
                          <tr
                            v-for="(row, index) in result_mesin[1]"
                            :key="index"
                            class="border-none"
                          >
                            <td>
                              <label>{{ row.title }}</label>
                              <p class="value">{{ row.value }}</p>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <!-- chasis  -->
              <div class="card" v-if="result_chasis.length > 0">
                <a
                  class="card-header collapsed"
                  data-toggle="collapse"
                  href="#spesifikasi3"
                  aria-expanded="false"
                >
                  <h6 class="section-description-sm mb-0 d-inline-block">
                    CHASSIS
                  </h6>
                </a>
                <div
                  id="spesifikasi3"
                  class="collapse"
                  data-parent="#accordion"
                  style=""
                >
                  <div class="card-body p-0">
                    <table class="table table-striped table-specification">
                      <tr>
                        <td class="p-0 border-none hemm">
                          <tr
                            v-for="(row, index) in result_chasis[0]"
                            :key="index"
                          >
                            <td>
                              <label>{{ row.title }}</label>
                              <p class="value">{{ row.value }}</p>
                            </td>
                          </tr>
                        </td>

                        <td class="p-0 border-none hemm">
                          <tr
                            v-for="(row, index) in result_chasis[1]"
                            :key="index"
                            class="border-none"
                          >
                            <td>
                              <label>{{ row.title }}</label>
                              <p class="value">{{ row.value }}</p>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-bottom-question">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 block-text section-md">
            <div class="box-section-title">
              <h2 class="section-heading">Have a question?</h2>
              <p class="section-description-sm">
                Apabila Anda memiliki pertanyaan seputar kendaraan Anda,
                silahkan hubungi kami melalui WhatsApp atau kunjungi dealer
                Nasmoco terdekat.
              </p>

              <div class="find-call">
                <a href="find_dealer.html" class="btn btn-padd btn-dark rounded"
                  ><span>FIND DEALER</span>
                  <i class="fa fa-caret-right ml-3"></i
                ></a>
                <a
                  href="https://wa.me/628981260060"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp px-4"
                  ><i class="fab fa-whatsapp font-weight-400 mr-2"></i
                  >WHATSAPP</a
                >
                <!-- <a href="https://wa.me/628981260060" target="_blank" class="btn btn-padd btn-white rounded"><i class="fa fa-phone mr-2"></i> <span>0898 - 1260060</span></a> -->
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 block-thumbnails bg-image center"
            data-background="assets/img/bg-2.png"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CompareCar",
  metaInfo() {
    return {
      title:
        "Compare Car - Dealer Toyota Semarang",
      meta: [
        {
          name: "description",
          content:
            "Dealer Toyota Semarang",
        },
        {
          name: "keywords",
          content:
            "Dealer Toyota Semarang, Harga Toyota Semarang, Promo Toyota Semarang, Trade in Toyota Semarang, Bengkel Toyota Semarang, Nasmoco Semarang, Toyota  Avanza, Toyota  Innova, Toyota  Agya, Toyota  FT86, Toyota Rush, Toyota  Fortuner, Toyota Calya, Nasmoco Semarang, Mobil Toyota Semarang",
        },
      ],
    };
  },
  data() {
    return {
      Car_Transmision: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
    };
  },
  created() {
    this.getCarCategory1();
    this.getCarCategory2();
    window.scrollTo(0, 0);
  },
  validations: {
    compare_car: {
      car_category_1: { required },
      car_model_1: { required },
      car_transmission_1: { required },
      car_type_1: { required },
      car_category_2: { required },
      car_model_2: { required },
      car_transmission_2: { required },
      car_type_2: { required },
    },
  },
  watch: {
    compare_car: {
      handler(newValue) {
        if (newValue.car_category_1 != "") {
          this.getCarModel1({
            category_id: newValue.car_category_1,
          });
        }
        if (newValue.car_transmission_1 != "" && newValue.car_model_1 != "") {
          this.getCarType1({
            car_model_id: newValue.car_model_1,
            car_transmission: newValue.car_transmission_1,
          });
        }
        if (newValue.car_category_2 != "") {
          this.getCarModel2({
            category_id: newValue.car_category_2,
          });
        }
        if (newValue.car_transmission_2 != "" && newValue.car_model_2 != "") {
          this.getCarType2({
            car_model_id: newValue.car_model_2,
            car_transmission: newValue.car_transmission_2,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
  computed: {
    ...mapState(["isSave"]),
    ...mapState("compare_car", {
      compare_car: (state) => state.compare_car,
      result_car_1: (state) => state.result_car_1,
      result_car_2: (state) => state.result_car_2,
      result_dimensi: (state) => state.result_dimensi,
      result_chasis: (state) => state.result_chasis,
      result_mesin: (state) => state.result_mesin,
      //car detail 1
      car_category_1: (state) => state.car_category_1,
      car_model_1: (state) => state.car_model_1,
      car_type_1: (state) => state.car_type_1,
      //car detail 2
      car_category_2: (state) => state.car_category_2,
      car_model_2: (state) => state.car_model_2,
      car_type_2: (state) => state.car_type_2,
    }),
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("compare_car", [
      "submitResult",
      "getCarCategory1",
      "getCarModel1",
      "getCarType1",
      "getCarCategory2",
      "getCarModel2",
      "getCarType2",
      "Clear_State",
      "Clear_State_Result_1",
      "Clear_State_Result_2",
    ]),

    // car 1
    setValIdkategoriMobil1(val) {
      this.compare_car.car_category_1 = val.category_id;
      this.compare_car.car_category_1_name = val.category_name;
    },
    setValIdCarModel1(val) {
      this.Clear_State_Result_1();
      this.compare_car.car_model_1 = val.car_model_id;
      this.compare_car.car_model_1_name = val.car_mode_name;
    },
    setValIdTransmisi1(val) {
      this.compare_car.car_transmission_1 = val.value;
      this.compare_car.car_transmission_1_name = val.label;
    },
    setValIdCarType1(val) {
      this.compare_car.car_type_1 = val.car_type_id;
      this.compare_car.car_type_1_name = val.car_type_name;
      this.result_car_1.image = val.image;
      this.result_car_1.price = val.price;
    },

    //car 2
    setValIdkategoriMobil2(val) {
      this.compare_car.car_category_2 = val.category_id;
      this.compare_car.car_category_2_name = val.category_name;
    },
    setValIdCarModel2(val) {
      this.Clear_State_Result_2();
      this.compare_car.car_model_2 = val.car_model_id;
      this.compare_car.car_model_2_name = val.car_mode_name;
    },
    setValIdTransmisi2(val) {
      this.compare_car.car_transmission_2 = val.value;
      this.compare_car.car_transmission_2_name = val.label;
    },
    setValIdCarType2(val) {
      this.compare_car.car_type_2 = val.car_type_id;
      this.compare_car.car_type_2_name = val.car_type__name;
      this.result_car_2.image = val.image;
      this.result_car_2.price = val.price;
    },

    //Submit Compare Car
    submit() {
      this.$Progress.start();
      this.submitResult()
        .then((res) => {
          this.CLEAR_ERRORS();
          this.$Progress.finish();
        })
        .catch((error) => {
          toast.fire({
            icon: "error",
            title: this.errors,
          });
          this.$Progress.fail();
        });
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>