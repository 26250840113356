import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueProgressBar from 'vue-progressbar'
import JQuery from 'jquery'
import wb from "./registerServiceWorker";
import VueCurrencyFilter from 'vue-currency-filter'
import BootstrapVue from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import swal from 'sweetalert2'
import money from 'v-money'
import Vuelidate from 'vuelidate'
import VuePannellum from 'vue-pannellum'
import VueThreeSixty from 'vue-360'
import "bootstrap";
import VueMeta from 'vue-meta'
import IdleVue from "idle-vue";

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000, // 3 seconds
  startAtIdle: false
});

// vue meta
Vue.use(VueMeta)

//create custom even for http request
window.Fire = new Vue();

// 360
Vue.use(VueThreeSixty)

// panellum
Vue.component('VPannellum', VuePannellum)
// vuelidate
Vue.use(Vuelidate)

// v-money
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'Rp. ',
  precision: 0,
  masked: false
})

//importing sweatalert notification
window.swal = swal;
//and used it
const toast = swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 4000,
  // timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  },

});
window.toast = toast;

// bootstrap date picker
Vue.use(datePicker)

//jquery
global.JQuery = JQuery

//adding filter for timestamp
Vue.filter('myDate', function (date) {
  return moment(date).locale('id').format('LL');
});
Vue.filter('myTime', function (date) {
  return moment(date).locale('id').startOf('hour').fromNow();;
});
Vue.filter('expDate', function (date) {
  var expiration = moment(date).format("YYYY-MM-DD");
  var current_date = moment().format("YYYY-MM-DD");
  var days = moment(expiration).diff(current_date, 'days');
  return days;
});
Vue.filter('myYear', function (date) {
  return moment(date).format('YYYY');
});
Vue.filter('myAge', function (date) {
  return moment().diff(date, 'years');
});

// v-select
Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
Vue.use(VueCurrencyFilter, {
  symbol: 'Rp.',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.prototype.$workbox = wb;

Vue.config.productionTip = false
//and used it
Vue.use(VueProgressBar, {
  color: 'rgb(255, 214, 0)',
  failedColor: 'red',
  height: '300px'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
